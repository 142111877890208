import React from 'react';
import { connect } from 'react-redux';
import { Hub } from '@aws-amplify/core';
import {
  withAuthenticator
} from 'aws-amplify-react';
import SignIn from 'components/Auth/SignIn';
import SignUp from 'components/Auth/SignUp';
import ConfirmSignUp from 'components/Auth/ConfirmSignUp'
import AutoSignIn from 'components/Auth/AutoSignIn'
import { 
  ConfirmSignIn, RequireNewPassword, 
  VerifyContact
} from 'aws-amplify-react';
import ForgotPassword from 'components/Auth/ForgotPassword';
import { authStatusChanged, authError } from 'actions/auth';
import { SIGNED_OUT, SIGN_UP } from 'store/constants';
import App from 'containers/App';
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()
 function _App() {
   return <QueryClientProvider client={queryClient}>
     <App/>
   </QueryClientProvider>
 }

const _Authenticator = withAuthenticator(_App, false, [
  <SignIn />,
  <ForgotPassword />,
  <ConfirmSignIn />,
  <RequireNewPassword />,
  <SignUp />,
  <ConfirmSignUp />,
  <VerifyContact />
]);

class Authenticator extends _Authenticator {
  componentDidMount() {
    Hub.listen(SIGNED_OUT, () => {
      this.handleAuthStateChange(SIGNED_OUT);
      queryClient.clear();
    });
  }

  handleAuthStateChange(state, data) {
    super.handleAuthStateChange(state, data);
    this.props.authStatusChanged(state, data);
  }

  render() {
    return (
      <AutoSignIn authState={this.state.authState}>
        {super.render()}
      </AutoSignIn>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  authStatusChanged: (state, data) => {
    dispatch(authStatusChanged(state, data));
    return null;
  },
  errorMessage: err => {
    dispatch(authError(err));
    return null;
  }
});

export default connect(
  () => {
    if (window.location.pathname === "/sign-up") {
      return {authState: SIGN_UP};
    }
    return {};
  },
  mapDispatchToProps
)(Authenticator);