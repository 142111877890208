import React from 'react';

function RowWrap(props) {
  return (
    <>
      {props.children}
    </>
  )
}

export default RowWrap;