import { Hub } from '@aws-amplify/core'

export function errorNotification(payload) {
  Hub.dispatch('notify', { 
    event: "error",
    payload
  });
}

export function warningNotification(payload) {
  Hub.dispatch('notify', { 
    event: "warning",
    payload
  });
}