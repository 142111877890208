import React from 'react'

function TermsInfo() {
  return (
    <p className="border-t border-gray-300 mt-8 py-3 relative text-sm leading-5 px-2 text-gray-500">
      By clicking Sign In, you agree to our <a className="text-gray-900 underline inline" href="https://hala.ai/terms-and-conditions/" rel="noopener noreferrer" target="_blank">Terms</a> and have read and acknowledge our <a className="text-gray-900 underline inline" href="https://hala.ai/privacy-policy/" rel="noopener noreferrer" target="_blank">Privacy Policy</a>.
    </p>
  )
}

export default React.memo(TermsInfo);