import React from 'react';
import cls from 'classnames';
import Icon from '../Icon';
import { 
  defInputClass, inputErrClass, inputTextSmClass
} from "./index";

function Input({
  className,
  value,
  prefix,
  error,
  name,
  icon,
  type,
  textSm = true,
  shadow = true, 
  onChange,
  ...props
}, ref) {

  const _onChange = React.useCallback((e) => {
    if (type === 'number' && e.target.value) {
      let v = Number(e.target.value);
      if (!isNaN(v)) {
        onChange(v);
      } else onChange(0);
    } else onChange(e);
  }, [onChange, type]);
  
  return (
    <div className={cls("relative", {
      "rounded-md shadow-sm": shadow,
      flex: prefix
    })}>
      {
        prefix && (
          typeof prefix === 'string'
            ? <span
              className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
            >
              {prefix}
            </span>
            : prefix
        )
      }
      <input
        ref={ref}
        id={name}
        value={value}
        name={name}
        type={type}
        onChange={_onChange}
        className={cls("form-input", defInputClass, className, {
          [inputTextSmClass]: textSm,
          'cursor-not-allowed': props.disabled,
          'text-gray-400': props.disabled,
          'flex-1 rounded-none rounded-r-md': prefix,
          'pr-10': error || icon,
          [inputErrClass]: error
        })}
        aria-invalid={error ? "true" : null}
        aria-describedby={error ? `${name}-error` : null}
        {...props}
      />
      {
        (error || icon) && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <Icon 
            className={cls("h-5 w-5", {
              "text-red-500": error,
              "text-gray-400": !error
            })}
            name={cls({
              "s-exclamation-circle": error,
              [icon]: icon
            })} 
          />
        </div>
      }
    </div>
  )
}

export default React.forwardRef(Input);