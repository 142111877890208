import {
  closeNotificationEvent,
  ERROR, SUCCESS, WARNING
} from 'store/constants';
import { Hub } from '@aws-amplify/core'

export function closeNotification(payload) {
  return {
    type: closeNotificationEvent,
    payload
  };
}

export function errorNotification(payload) {
  return {
    type: ERROR,
    payload
  };
}

export function successNotification(payload) {
  return {
    type: SUCCESS,
    payload
  };
}

export function warningNotification(payload) {
  return {
    type: WARNING,
    payload
  };
}

export function notificationSubscriber(store) {
  Hub.listen('notify', ({payload}) => {
    switch(payload.event) {
      case 'error':
        store.dispatch(errorNotification(
          payload.payload?.title || payload.payload?.description 
          ? payload.payload 
          : {error: payload.payload})
        );
        break;
      case 'success':
        store.dispatch(successNotification(payload.payload));
        break;
      case 'warning':
        store.dispatch(warningNotification(payload.payload));
        break;
      default:
        break;
    }
  })
}