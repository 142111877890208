import React from 'react';

function DetailedHeader() {
  return (
    <div className="opacity-0 text-right">
      Details
    </div>
  ) 
}

export default React.memo(DetailedHeader)