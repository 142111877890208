import React from 'react';
import Select from 'components/Form/Select';
import { TableContext } from '../../Beta';
import { styles } from 'components/FormElements/RSelect/defaultOptions';

const cStyles = {
  input: (base, props) => {
    return {
      ...styles.input(base, props),
      paddingBottom: "0.875rem",
      paddingLeft: "1.5rem",
      paddingTop: "0.875rem",
    };
  },
  control: (base, props) => ({
    ...styles.control(base, props),
    borderRadius: 0
  }),
  singleValue: (base, props) => ({
    ...styles.singleValue(base, props),
    paddingLeft: "1.5rem"
  }),
  placeholder: (base, props) => ({
    ...styles.placeholder(base, props),
    paddingLeft: "1.5rem"
  })
}

function EditableSelectItem({
  saveAndClose, ...props
}) {
  const [open, setOpen] = React.useState(false);
  const { setState } = React.useContext(TableContext);

  React.useEffect(() => {
    setOpen(true);
    setState(st => ({
      ...st,
      tableOverflow: false
    }))
    return () => {
      setState(st => ({
        ...st,
        tableOverflow: true
      }))
    }
  }, [setState]);

  const onChange = React.useCallback(() => {
    saveAndClose()
  }, [saveAndClose]);

  return <Select
    autoFocus
    blurInputOnSelect
    closeMenuOnSelect
    menuShouldScrollIntoView
    menuIsOpen={open}
    onChange={onChange}
    styles={cStyles}
    {...props}
  />
}

export default EditableSelectItem;