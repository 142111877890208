import React from 'react';
import Input from 'components/Form/Input';

function EditableInputItem({
  saveAndClose, ...props
}) {

  // const onFocus = React.useCallback(e => e.target.select(), []);

  return <Input
    autoFocus
    shadow={false}
    // onFocus={onFocus}
    className="rounded-none py-3.5 pl-6 pr-4"
    {...props}
  />
}

export default EditableInputItem;