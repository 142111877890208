import React from 'react';
import cls from 'classnames';
import NotificationItem from './NotificationItem';
import { Hub } from '@aws-amplify/core'

function Notifications({
  className,
  items,
  onItemClose,
  ...props
}) {

  React.useEffect(() => {
    try {
      const location = window.location;
      const queryParams = new URLSearchParams(location.search);
      if (queryParams.has('int_error')) {
        let err = queryParams.get('int_error');
        switch (err) {
          case 'access_denied':
            err = `It looks like you don't have permission to add connectivity between your Integration and Hala`;
            break;
          default:
            break;
        }
  
        Hub.dispatch('notify', {
          event: "error",
          payload: {
            title: "Integration connection error",
            description: err
          }
        })
        queryParams.delete('int_error');
        window.history.pushState({}, document.title, window.location.pathname);
      }
    } catch(err){}
  }, []);

  return (
    <div 
      {...props}
      className={cls(
        "fixed flex-col inset-0 flex items-end justify-start px-4 py-6 pointer-events-none sm:p-6 z-40",
        className
      )}
    >
      {
        items.map(el => (
          <NotificationItem 
            key={el.id}
            id={el.id}
            onClose={onItemClose}
            {...el.data}
          />
        ))
      }
    </div>
  )
}

export default React.memo(Notifications);