import React from 'react';
import moment from 'moment';
import {
  compile, currencyTpl
} from 'utils/hb';
import cls from 'classnames';
import Icon from 'components/Icon';
import FileLink from 'components/FileLink'
import CellLoader from './CellLoader'

const numberTpl = compile(`{[numeral value format="0,0.[00]"]}`);
const empty = '';

export function render(value, renderer = {}, row) {
  const {
    type, props: renderProps = {}, tpl
  } = renderer || {};
  const _tpl = tpl ? compile(tpl) : null;

  switch (type) {
    case "loader":
      return <CellLoader/>
    case "date":
      try {
        return moment(value).format(renderProps.format || 'L');
      } catch (err) {
        return empty;
      }
    case "percent": {
      if (!value) {
        return empty;
      }
      if (!value.includes('%')) {
        value = value + ' %';
      }
      return value;
    }
    case "number": {
      return value ? numberTpl({ value }) : empty;
    }
    case "currency": {
      if (!value && value !== 0) {
        return empty;
      }
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: _tpl
              ? _tpl({ value, row })
              : currencyTpl({ value, currency: renderer.currency })
          }}
        />
      )
    }
    case 'file':
      return <FileLink file={value} {...renderProps}/>
    case 'link':
      if (!value) {
        return null
      }
      return (
        <a
          className="text-indigo-600 hover:text-indigo-900 font-medium cursor-pointer"
          onClick={e => e.stopPropagation()}
          rel="noopener noreferrer"
          target="_blank"
          href={renderProps.href ? compile(renderProps.href)({ value, row }) : (value?.includes('http') ? value : null)}
        >
          {renderProps.label ? compile(renderProps.label)({ value, row }) : value}
        </a>
      );
    case 'html':
      if (_tpl) {
        return <span
          dangerouslySetInnerHTML={{
            __html: _tpl({ value, row })
          }}
        />
      }
      break;
    default:
      break;
  }

  switch (typeof value) {
    case 'object': {
      if (Array.isArray(value)) {
        return (
          <div className="flex flex-wrap">
            {value && value.map((el, key) => (
              <span key={key} className="m-1 inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-blue-100 text-blue-800">
                {el}
              </span>
            ))}
          </div>
        )
      } else if (!value) {
        return '-';
      } else if (value.label) {
        return value.label;
      } else {
        return JSON.stringify(value)
      }
    }
    case 'boolean': {
      return (
        <div className="flex items-center">
          <Icon
            className={cls("flex-shrink-0 mr-1.5 h-5 w-5", {
              'text-green-400': value,
              'text-red-400': !value
            })}
            name={value ? 's-check-circle' : 's-x-circle'}
          />
          {
            value
              ? 'Yes'
              : 'No'
          }
        </div>
      )
    }
    case 'string': {
      if (value && value.includes('%')) {
        return value;
      }
      break;
    }
    default:
      break;
  }

  return value || (
    <span className="opacity-0">
      -
    </span>
  );
}

function DefaultCell(props) {
  const { original, values } = props.row;
  const value = React.useMemo(() =>
    render(props.value, props.column.renderer, {
      original, values
    })
    , [props.value, props.column.renderer, original, values]);

  return value;
}

export const CellWrap = React.memo((params) => {
  const { cell } = params;
  const { className } = cell.column.props || {};
  return (
    <div
      {...cell.getCellProps()}
      className={cls("px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-800", className)}
    >
      <DefaultCell {...params} />
    </div>
  )
})

export default React.memo(DefaultCell);