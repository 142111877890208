import React from 'react';
import RSelect from './RSelect';
import { createOption } from './index';

function MultiSelectInput({
  onBlur, ...props
}) {
  const [inputValue, setInputValue] = React.useState('');
  const handleInputChange = React.useCallback(val => setInputValue(val), []);
  const {value, onChange, options} = props;
  const save = React.useCallback(() => {
    if (!inputValue) return;
    const _val = value ? value : [];
    if (_val.find(el => el.label === inputValue)) return;
    onChange && onChange([..._val, createOption(inputValue)]);
    setInputValue('');
  }, [inputValue, value, onChange]);

  const menuIsOpen = React.useMemo(() => 
    !options || !options.length ? false : undefined
  , [options]);

  const handleKeyDown = React.useCallback(e => {
    switch (e.key) {
      case 'Enter':
      case 'Tab':
        save();
        e.preventDefault();
        break;
      default:
        break;
    }
  }, [save]);

  const _onBlur = React.useCallback(e => {
    onBlur && onBlur(e);
    save();
  },[onBlur, save]);

  return <RSelect 
    inputValue={inputValue}
    isClearable
    isMulti
    menuIsOpen={menuIsOpen}
    onBlur={_onBlur}
    onInputChange={handleInputChange}
    onKeyDown={handleKeyDown}
    placeholder=""
    {...props}
  />;
}

export default React.memo(MultiSelectInput);
