import React from 'react';
import { ConfirmSignUp } from 'aws-amplify-react';
import AuthLayout from '../Layout';
import { SIGN_IN } from 'store/constants'
import FormItem from 'components/FormElements/FormItem'
import Input from 'components/FormElements/Input'
import Button from 'components/Button';

class MyConfirmSignUp extends ConfirmSignUp {

  showComponent() {
    const { hide } = this.props;
    if (hide && hide.includes(MyConfirmSignUp)) {
      return null;
    }
    const username = this.usernameFromAuthData();
    return (
      <AuthLayout
        title="Confirm Sign Up"
        description={
          <>
            back to
            <span
              onClick={() => this.changeState(SIGN_IN)}
              className="pl-1 cursor-pointer font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
            >
              Sign In
            </span>
          </>
        }
      >
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="text-center leading-6 text-gray-900 font-semibold tracking-wide">
              {username || 'mykyta.bazhenov@hala.ai'}
            </div>
            <FormItem
              className="mt-6"
              label="Confirmation Code"
              name="code"
            >
              <Input 
                autoFocus
                name="code"
                autoComplete="off"
                placeholder="Enter your code"
                onChange={this.handleInputChange}
              />
            </FormItem>
            <div className="mt-6 flex items-center justify-between">
              <span className="text-sm leading-5 text-gray-900">
                Lost your code?
              </span>
              <span onClick={this.resend} className="text-sm cursor-pointer leading-5 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                Resend Code
              </span>
            </div>
            <div className="mt-6">
              <Button 
                onClick={this.confirm}
                type="button"
                className="w-full"
                primary
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </AuthLayout>
    )
  }
}

export default MyConfirmSignUp;