import React from 'react';
import ReactDOM from 'react-dom';

function Portal({
  name, 
  children,
  className
}) { 
  const el = React.useMemo(() => {
    const _el = document.createElement('div');
    if (className) className.split(' ').forEach(cl => {
      cl = cl.trim();
      cl && _el.classList.add(cl);
    });
    return _el;
  }, [className]);
  
  React.useEffect(() => {
    const root = document.getElementById(name);
    if (root) {
      root.appendChild(el);
      return () => root.removeChild(el);
    }
  }, [name, el]);

  return ReactDOM.createPortal(children, el);
}

export default React.memo(Portal);