import React from 'react';
import { animated, useTransition } from 'react-spring';
import { 
  easeExpOut
} from 'd3-ease';

export const opacity = {
  config: { 
    duration: 600,
    easing: easeExpOut
  },
  from: { opacity: 0 },
  enter: { opacity: 1 },
  leave: { opacity: 0 }
};

export const fromRight = {
  config: { 
    duration: 600,
    easing: easeExpOut
  },
  from: { transform: 'translateX(100%)' },
  enter: { transform: 'translateX(0)' },
  leave: { transform: 'translateX(100%)' }
};

  // if (delay) {
  //   let _enter = o.enter;
  //   o.enter = item => async (next, cancel) => {
  //     await new Promise(resolve => setTimeout(resolve, delay));
  //     await next(_enter)
  //   }
  // }


function Spring({
  show, options,
  ...props
}) {

  const t = useTransition(show, null, options);

  return t.map(({ item, key, props: style }) => item ?
    <animated.div key={key} style={style} {...props}/>
  : null);
}

export default React.memo(Spring);