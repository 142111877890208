import React from 'react'
import cls from 'classnames';
import ItemKey from './ItemKey'
import ItemValue from './ItemValue'

export const Wrap = React.memo(({
  children, className
}) => {
  return (
    <div className={className}>
      <dl className="divide-y divide-gray-200">
        {children}
      </dl>
    </div>
  )
});

export const Item = React.memo(({
  className, name, value
}) => {
  return (
    <div
      className={cls("py-4 space-y-1 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4", className)}
    >
      <ItemKey>{name}</ItemKey>
      <ItemValue className="flex space-x-4 sm:mt-0 sm:col-span-2">{value}</ItemValue>
    </div>
  )
});