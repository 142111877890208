import React from 'react';
import RSelect from './RSelect';
import RSelectRemote from './RSelectRemote';
import _get from 'lodash/get';
import defProps from './defaultOptions';
import MultiSelectInput from './MultiSelectInput';
import { iconsOptions } from 'components/Icon';
import countries from 'utils/country_list'

function convertByRemoteType(type, option, options) {
  switch(type) {
    case 'quickbooks': {
      const parentId = option?.ParentRef?.value;
      if (parentId) {
        const parent = options.find(({Id}) => Id === parentId);
        if (parent) {
          Object.keys(parent).forEach(key => {
            if (!option[key] && parent[key]) {
              option[key] = parent[key];
            }
          });
        }
      }
      break;
    }
    default:
      break;
  }

  return option;
}

export function convertOptions(options, optionsFormat, remoteType) {
  const { path, item, name } = optionsFormat || {};
  switch(name) {
    case 'countries':
      return countries.map(el => ({
        value: el.value,
        label: el.value,
        inlineIcon: el.map,
        data: el
      }));
    case 'icons':
      return iconsOptions;
    default:
      break;
  }
  let opts = options;
  if (!opts) {
    return [];
  }
  if (path) {
    opts = _get(opts, path, []);
  }
  if (!Array.isArray(opts)) {
    return [];
  }
  return opts.map(o => {
    if (typeof o === 'string') {
      return {
        value: o,
        label: o
      }
    } else if (item) {
      if (remoteType) {
        o = convertByRemoteType(remoteType, o, opts);
      }
      return {
        ...o,
        value: _get(o, item.value, o.value),
        label: _get(o, item.label, o.label)
      }
    }
    return o;
  });
}

export const createOption = label => ({
  label,
  value: label
})

function RSelectWrap({
  styles, components, ...props
}) {
  const isMulti = props.creatable && !props.remote && props.isMulti;
  const {options} = props;
  const noOptions = React.useMemo(() => !options || !options.length, [options])
  
  const st = React.useMemo(() => {
    return {
      styles: Object.assign({}, defProps.styles, styles || {}),
      components: Object.assign(
        {}, 
        defProps.components, 
        noOptions ? {
          DropdownIndicator: null
        } : {},
        components || {}
      )
    }
  }, [styles, components, noOptions]);

  props.styles = st.styles;
  props.components = st.components;

  const _remote = props.remote ? true : false;
  const Comp = React.useMemo(() => {
    if (_remote) return RSelectRemote;
    if (isMulti) return MultiSelectInput;
    return RSelect;
  }, [_remote, isMulti]);

  return <Comp {...props}/>;
}

export default RSelectWrap;