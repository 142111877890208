import React from 'react'
import cls from 'classnames'

function SectionHeader({
  title, description,
  className, children, actions,
  primary = false, secondary = false, border = false,
  ...props
}) {
  return (
    <header 
      className={cls("space-y-1 py-6 px-4 sm:px-6", {
        "bg-indigo-700": primary,
        "bg-gray-50": secondary,
        "border-b border-gray-200": border
      }, className)}
      {...props}
    >
      {title && <div className="flex items-center justify-between space-x-3">
        <h2 
          className={cls("text-lg leading-7 font-medium", {
            "text-white": primary,
            "text-gray-900": !primary
          })}
        >
          {title}
        </h2>
        {actions}
      </div>}
      {description && <div>
        <p className={cls("text-sm leading-5", {
          "text-indigo-300": primary,
          "text-gray-500": !primary
        })}>
          {description}
        </p>
      </div>}
      {children}
    </header>
  )
}

export default React.memo(SectionHeader);