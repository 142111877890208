import React from 'react'

function FooterCell({column}) {
  const hasTotal = column.total === true;

  if (hasTotal) {
    return column.render('FooterTotal');
  }

  return column.render('Footer');
}

export default React.memo(FooterCell);