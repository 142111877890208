import React from 'react';
import Header from './Header';

function HeaderGroup({ header }) {
  return (
    <tr {...header.getHeaderGroupProps()}>
      {header.headers.map(column => (
        <th {...column.getHeaderProps(
          column.getSortByToggleProps 
          ? column.getSortByToggleProps()
          : {}
        )}>
          <Header column={column}/>
        </th>
      ))}
    </tr>
  )
}

export default HeaderGroup;