import produce from "immer";
import {
  apis,
  closeNotificationEvent,
  ERROR, SUCCESS, WARNING,
  DISMISS_ERROR,
  AUTH_ERROR,
  SIGNED_OUT
} from '../constants';
import { v4 as uuid } from 'uuid';

const _default = 'I am sorry there is system error in our application, could you please try again later.';
function processError({
  title = 'Oops something went wrong', 
  description = _default,
  error,
  timeout
}) {
  description = error || description;

  if (typeof description === 'object') {
    title = description.title || title;
    description = description.description || description;
  }
  
  if (typeof description !== 'string') {
    if (description.response && description.response.data) {
      description = description.response.data;
      if (description.error) {
        description = description.error;
      }
    } else if (description.message) {
      description = description.message;
    }
  }

  switch (description) {
    case 'Not found':
      description = _default;
      break;
    case 'PreAuthentication failed with error Access denied!.':
      description = 'You have been denied access to the portal!';
      break;
    case 'Network Error':
      description = 'You are not authorized to perform this action!';
      break;
    case "Error	Vähemalt ühel real peab reasumma määratud olema3":
      description = `Price shouldn't be equal to 0`;
      break;
    default:
      break;
  }
 
  return {
    title,
    description,
    icon: 'o-exclamation-circle',
    iconCls: 'text-red-400',
    timeout
  };
}

function findIndex(els, findId) {
  return els.findIndex(({id}) => id === findId);
}

const initState = [];

export default produce((draft, {type, payload}) => {
  switch (type) {
    case AUTH_ERROR:
      draft.unshift({
        id: uuid(),
        data: processError({
          title: 'Authentication Error',
          description: payload.error || payload.message || payload
        })
      });
      break;
    case WARNING:
      draft.unshift({
        id: uuid(),
        data: {
          icon: 'o-exclamation',
          iconCls: 'text-yellow-400',
          ...payload
        }
      });
      break;
    case SUCCESS:
      draft.unshift({
        id: uuid(),
        data: {
          icon: 'o-check-circle',
          iconCls: 'text-green-400',
          ...payload
        }
      });
      break;
    case ERROR:
    case apis.fetchError:
      let d = processError(payload);
      const i = payload.id ? findIndex(draft, payload.id) : -1;
      if (i >= 0) {
        draft[i] = {
          id: payload.id,
          data: {
            ...draft[i].data,
            ...d
          }
        };
      } else draft.unshift({
        id: payload.id || uuid(),
        data: d
      });
      break;
    case DISMISS_ERROR:
    case closeNotificationEvent:
      if (typeof payload !== 'undefined') {
        const index = findIndex(draft, payload);
        if (index !== -1) {
          draft.splice(index, 1);
        }
      } else {
        return initState;
      }
      break;
    case SIGNED_OUT:
      return initState;
    default:
      break;
  }
}, initState);