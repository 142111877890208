import { useMemo } from 'react';

function useInstance(instance) {
  const {
    columns,
  } = instance;

  const withFooter = useMemo(() => {
    return columns.some(col => {
      return typeof col.Footer === 'string' || col.total === true;
    });
  }, [columns]);
  
  Object.assign(instance, {
    withFooter
  });
}

export default function hook(hooks) {
  hooks.useInstance.push(useInstance);
}