import React from 'react';
import Checkbox from '../FormElements/Checkbox';
import { useFormContext, Controller } from "react-hook-form";
import { useFormItemError } from 'hooks/useFormItemError';

function CheckboxInput ({
    name, value, validationOptions, 
    ...props
}) {
    const { control } = useFormContext();
    // const defVal = React.useMemo(() => {
    //     if (typeof value === 'string') {
    //         return value === 'true';
    //     }
    //     return value ? true : false;
    // }, [value]);
    const { ref, error } = useFormItemError(name);

    return <Controller 
        render={({ onBlur, onChange, value, ...rest }) => (
            <Checkbox 
                ref={ref}
                {...rest}
                {...props}
                error={error}
                checked={value}
                name={name}
                onChange={e => {
                    onChange(
                        typeof e === 'boolean'
                        ? e
                        : e.target.checked
                    )
                }}
                onBlur={onBlur}
            />
        )}
        name={name}
        control={control}
        // defaultValue={defVal}
    />;
};

export default CheckboxInput;