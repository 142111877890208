import React from 'react';
import cls from 'classnames';

function ItemKey({
  className, children
}) {
  return (
    <dt className={cls("text-sm leading-5 font-medium text-gray-500", className)}>
      { children }
    </dt>
  )
}

export default React.memo(ItemKey);