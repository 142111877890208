import React from 'react';
import * as solid from './heroicons/solid';
import * as outline from './heroicons/outline';
import { ReactComponent as oval } from './oval.svg';

const icons = {
    'oval': oval
};

export const iconsOptions = Object.keys(solid).reduce((els, name) => {
    els.push({
        label: `${name} (solid)`, 
        value: `s-${name}`,
        icon: `s-${name}`
    },{
        label: `${name} (outline)`, 
        value: `o-${name}`,
        icon: `o-${name}`
    });
    return els;
}, []);

const capitalize = s => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

function getIcon(name) {
    if (icons[name]) {
        return icons[name];
    }
    let n = name.split('-');
    let coll = outline;
    switch(n.shift()) {
        case 'o':
            coll = outline;
            break;
        case 's':
            coll = solid;
            break;
        default:
            break;
    }
    
    if (coll) {
        if (!coll[n]) {
            n = n.reduce((name, el) => {
                return name + capitalize(el);
            }, '');
        }
        if (coll[n]) {
            return coll[n];
        }
    }

    console.error('icon not found', n);

    return null;
}

function Icon({
    name, ...props
}) {
    const Comp = React.useMemo(() => getIcon(name), [name]);
    if (!Comp) return null;
    return <Comp {...props} />;
}

export default React.memo(Icon);