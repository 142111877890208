import React from 'react'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { convertOptions } from './index'
import useSelectValue from './hooks/useSelectValue';

function RSelect({
  creatable, options, optionsFormat,
  value, ...props
}) {

  const Comp = React.useMemo(() => {
    if (creatable) return CreatableSelect;
    return Select;
  }, [creatable]);

  const _options = React.useMemo(() => {
    return convertOptions(options, optionsFormat);
  }, [options, optionsFormat]);

  value = useSelectValue(value, _options, props.onChange);

  return (
    <Comp
      options={_options}
      menuPlacement="auto"
      value={value}
      {...props}
    />
  );
}

export default React.memo(RSelect);