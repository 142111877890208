import { useMemo, useState, useCallback } from 'react';

const defState = {
  tableOverflow: true
}

function useInstance(instance) {
  const {
    canAddRow,
    onChange,
    addRowText,
    itemForm, slideover,
    visibleColumns
  } = instance;
  const [state, setState] = useState(defState);
  const setOverflow = useCallback(tableOverflow => {
    setState(st => ({...st, tableOverflow}))
  }, []);
  
  const extend = useMemo(() => {
    return {
      context: {
        canAddRow,
        onChange,
        addRowText,
        itemForm, slideover,
        visibleColumns,
        setState, setOverflow,
        ...state
      }
    }
  }, [
    canAddRow,
    onChange,
    addRowText,
    itemForm, slideover,
    visibleColumns,
    setState, setOverflow,
    state
  ]);

  Object.assign(instance, extend);
}

export default function hook(hooks) {
  hooks.useInstance.push(useInstance);
}