import React from 'react';

function Row({ row }) {
  return (
    <tr {...row.getRowProps()}>
      {row.cells.map(cell => {
        return <td {...cell.getCellProps()}>
          {
            typeof row.canExpand === 'undefined'
            ? cell.render(cell.cellRender || row.cellRender || 'Cell')
            : cell.render('ExpandedCell')
          }
        </td>
      })}
    </tr>
  )
}

export default Row;