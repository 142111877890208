import HB from "handlebars";
import numeral from "numeral";
import moment from 'moment';
import * as math from 'mathjs';

function numberHelper(val, ...params) {
  const { hash } = params.pop();
  const fns = Object.keys(hash);
  if (typeof val !== 'undefined') {
    val = Number(val)
  }
  fns.forEach(f => {
    if (val[f]) {
      val = val[f](parseInt(hash[f]));
    }
  })
  return new HB.SafeString(val);
}

export const currencySymbols = {
  'USD': '$', // US Dollar
  'CAD': '$', // Canadian Dollar
  'EUR': '€', // Euro
  'CRC': '₡', // Costa Rican Colón
  'GBP': '£', // British Pound Sterling
  'ILS': '₪', // Israeli New Sheqel
  'INR': '₹', // Indian Rupee
  'JPY': '¥', // Japanese Yen
  'KRW': '₩', // South Korean Won
  'NGN': '₦', // Nigerian Naira
  'PHP': '₱', // Philippine Peso
  'PLN': 'zł', // Polish Zloty
  'PYG': '₲', // Paraguayan Guarani
  'THB': '฿', // Thai Baht
  'UAH': '₴', // Ukrainian Hryvnia
  'VND': '₫', // Vietnamese Dong
};

function getCurrencySymbol(val) {
  return typeof val === 'string' ? (currencySymbols[val.toUpperCase()] || val) : '';
}

function numeralHelper(val, ...params) {
  const { hash } = params.pop();
  try {
    let v = numeral(val);
    if (hash.format) {
      v = v.format(hash.format);
    }
    if (hash.currency) {
      let m = '';
      if (v.charAt(0) === '-') {
        v = v.substring(1);
        m = '-';
      }
      if (hash.currencyClass) {
        v = m + `<span class="${hash.currencyClass}">${(currencySymbols[hash.currency]||hash.currency)}</span>` + v;
      } else {
        v = m + (currencySymbols[hash.currency]||hash.currency) + v;
      }
    }
    return new HB.SafeString(v);
  } catch (err) { }
  return val;
}

HB.registerHelper('number', numberHelper);
HB.registerHelper('numeral', numeralHelper);
HB.registerHelper('fn', function (...args) {
  const { hash } = args.pop();
  return {
    args,
    fn: hash?.name
  };
});

const libs = {
  moment, math,
  numeral: numeral,
  Math,
  array: Array,
  str: String
}

HB.registerHelper('lib', function (...args) {
  const { hash: { name } } = args.pop();
  if (!libs[name]) {
    return null;
  }
  const {
    libArgs,
    funcs
  } = args.reduce((r, arg) => {
    if (!arg) return r;
    if (arg.args || arg.fn) {
      r.funcs.push(arg);
    } else {
      r.libArgs.push(arg);
    }
    return r;
  }, { libArgs: [], funcs: [] });
  let res = null;
  try {
    if (typeof libs[name] === 'function') {
      res = libs[name].apply(libs[name], libArgs);
    } else {
      res = libs[name]
    }
    funcs.forEach(({ args, fn }) => {
      if (res[fn]) {
        res = res[fn].apply(res, args);
      }
    });
  } catch (err) {
  }
  if (!['string', 'number'].includes(typeof res)) switch (name) {
    case 'moment':
      return res.format();
    case 'math':
      return 0;
    default:
      return res;
  }
  return res;
});

HB.registerHelper('currency', function(val) {
  return getCurrencySymbol(val);
})

HB.registerHelper('hb', function (...args) {
  const {data: {root}} = args.pop();
  args = args.map(el => HB.compile(el)(root));
  if (args.length === 1) {
      return args[0];
  }
  return JSON.stringify(args);
})

HB.registerHelper('mathEval', function(str, ...args) {
  const {data: {root}, hash: {format}} = args.pop();
  if (str.includes('{{')) {
    str = HB.compile(str)(root);
  }
  str = `lib (fn (hb '${str}') name='evaluate') name='math'`;
  if (format) {
    str = `numeral (${str}) format='${format}'`
  }
  return HB.compile(`{{${str}}}`)(root);
});

export function compile(srt = '') {
  if (srt.includes('<hl>')) {
    srt = srt.replace(/<hl>/g, "{{").replace(/<\/hl>/g, "}}");
  }
  if (srt.includes('{[[') && srt.includes(']]}')) {
    srt = srt.replace(/\{\[\[/g, "{{{").replace(/\]\]\}/g, "}}}");
  }
  if (srt.includes('{[') && srt.includes(']}')) {
    srt = srt.replace(/\{\[/g, "{{").replace(/\]\}/g, "}}");
  }
  return HB.compile(srt);
}

export const currencyTpl = compile(`{[numeral value format="0,0.[00]" currency=currency currencyClass=currencyClass]}`);
export const currencyShortTpl = compile(`{[numeral value format="0,[0]a" currency=currency currencyClass=currencyClass]}`);

export function escapeString(str) {
  str = str.replace(/(<([^>]+)>)/gi, "");
  return HB.Utils.escapeExpression(str)
}
// console.log('!!!!', compile(`{[mathEval '{{HourlyRate}}/{{Hours}}' format='0,0.[00]' ]}`)({
//   HourlyRate: 3,
//   Hours: 24.5,
//   Minutes: 20
// }))