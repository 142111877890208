import React from 'react'
import numeral from 'numeral'
import { render } from '../DefaultCell'

function FooterTotal({column, rows}) {

  const {id, renderer} = column;
  const total = React.useMemo(() => {
    const val = rows.reduce((sum, row) => {
      if (typeof row.values[id] !== 'undefined'){
        return numeral(sum).add(row.values[id]).value()
      }
      return sum;
    }, 0);
    if (renderer) {
      return render(val, renderer, {})
    }
    return val;
  }, [rows, id, renderer])

  return total;
}

export default React.memo(FooterTotal);