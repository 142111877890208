import React from 'react';
import { components } from 'react-select';

export const multiValueStyles = base => ({
});

function MultiValue(props) {
    return (
        <components.MultiValue
            {...props} 
            className="m-1 inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-blue-100 text-blue-800"
        >
            { props.children }
        </components.MultiValue>
    );
};

export default MultiValue;