import React from 'react';
import { useFormContext } from "react-hook-form";
import isMatch from 'lodash/isMatch';
import FormItems from './FormItems';

function ConditionalItem({
  values, items
}) {
  const { watch } = useFormContext();
  const fields = watch();

  if (isMatch(fields, values)) {
    return (
      <FormItems items={items} />
    );
  }

  return null;
}

export default ConditionalItem;