import {
  WELLCOME_BUTTONS,
  MESSAGE_LOADRER
} from 'store/constants';

export const setMessageLoader = payload => ({
  type: MESSAGE_LOADRER, 
  payload
});

export const setWellcomeButtons = payload => ({
  type: WELLCOME_BUTTONS, 
  payload
});