import intuilTableData from './table';
import intuilChartData from './chart';
import intuilPieChartData from './pieChart';

export function intuilData(data, options = {}) {
  switch(options.type) {
    case 'table':
      return intuilTableData(data, options);
    case 'chart':
      return intuilChartData(data, options);
    case 'pieChart':
      return intuilPieChartData(data, options);
    default:
      return null;
  }
}