import { createOption } from '../index'
import { useEffect, useMemo } from 'react';

function findOptionOrCreate(value, options) {
  if (value) {
    if (typeof value === 'string') {
      let val = createOption(value);
      if (options) {
        val = options.find(o => o.value === value) || val;
      }
      return val;
    } else if (Array.isArray(value) && value.find(c => typeof c === 'string')) {
      return value.map(v => findOptionOrCreate(v, options) || v);
    } else if (typeof value === 'object' && !value.label && !value.label) {
      if (value.id && value.name) {
        return {
          label: value.name,
          value: value.id
        }
      }
    }
  }
  return null;
}

export default function useSelectValue(value, options, onChange, isRemote) {
  let newVal = useMemo(() => {
    if (isRemote && (!options || !options.length)) {
      return null;
    }
    return findOptionOrCreate(value, options)
  }, [value, options, isRemote]);

  useEffect(() => {
    if (newVal) onChange(newVal);
  }, [onChange, newVal])

  if (isRemote && (!options || !options.length)) {
    return null;
  }

  return newVal ? newVal : value;
}