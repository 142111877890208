import { setCurrentUser } from 'actions/auth';
import { socket } from 'actions/socket';
import { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { refreshChat } from 'actions/input'
import useCurrentUser from 'hooks/user/useCurrentUser'

export default function useInitApp(dispatch) {
  const { data } = useCurrentUser();
  const queryCache = useQueryClient();
  
  useEffect(() => {
    data && dispatch(setCurrentUser(data));
  }, [data, dispatch]);

  useEffect(() => {
    const onUserInfo = user => dispatch(setCurrentUser(user));
    const onRefresh = () => {
      dispatch(refreshChat);
      queryCache && queryCache.resetQueries();
    };
    socket.on('userInfo', onUserInfo);
    socket.on('refresh', onRefresh);
    return () => {
      socket.off('userInfo', onUserInfo);
      socket.off('refresh', onRefresh);
    }
  }, [dispatch, queryCache]);
}