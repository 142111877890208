import React from 'react';
import { components } from 'react-select';
import classnames from 'classnames';

export const valueContainerStyles = base => ({
    ...base,
    padding: "0",
});

function ValueContainer(props) {
    return (
        <components.ValueContainer {...props}
            className={classnames('sm:text-sm sm:leading-5', {
                'flex flex-row': props.isMulti
            })}
        >
            { props.children }
        </components.ValueContainer>
    );
};

export default ValueContainer;