import React from 'react';
import { useFormContext } from "react-hook-form";
import DatePicker from '../FormElements/DatePicker';
import useFormFieldOptions from 'hooks/useFormFieldOptions';
import moment from 'moment';

function DatePickerInput({
  name, validationOptions,
  fieldOptions, value,
  ...props
}) {
  const { register, unregister, setValue, watch, errors } = useFormContext();
  const error = errors && errors[name] && errors[name].message;
  let _value = watch(name);

  const _onChange = React.useCallback(d => {
    setValue(name, d ? d.startOf('day').format() : null, {shouldValidate: true});
  }, [setValue, name]);

  React.useEffect(() => {
    register({ name }, validationOptions);
    return () => unregister(name);
  }, [name, register, unregister, validationOptions]);

  React.useEffect(() => {
    if (value && typeof value === 'string') {
      try {
        _onChange(moment(value))
      } catch (err) {}
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useFormFieldOptions(name, fieldOptions);

  return (
    <DatePicker
      {...props}
      name={name}
      error={error}
      onChange={_onChange}
      value={_value}
    />
  );
}

export default DatePickerInput;