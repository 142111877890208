import produce from "immer";
import {
  MESSAGE_LOADRER,
  WELLCOME_BUTTONS,
  SIGNED_OUT, SHOW_REFRESH,
  CLIENT_MESSAGE, SET_WORKSPACE
} from '../constants';
import { createSelector } from 'reselect';

const initState = {
  messageLoader: false,
  showRefresh: false,
  workspace: {
    id: '',
    name: ''
  },
  wellcomeButtons: [
    "Hello",
    "What are your capabilities?"
  ]
};

export default produce((draft, { type, payload }) => {
  switch (type) {
    case SHOW_REFRESH:
      draft.showRefresh = payload;
      break;
    case SET_WORKSPACE:
      draft.workspace = payload;
      break;
    case CLIENT_MESSAGE:
    case MESSAGE_LOADRER:
      draft.messageLoader = payload;
      break;
    case WELLCOME_BUTTONS:
      if (payload && payload.length) {
        draft.wellcomeButtons = payload;
      }
      break;
    case SIGNED_OUT:
      return initState;
    default:
      break;
  }
}, initState);

export const configSelector = state => state.config;
export const workspaceSelector = createSelector(
  configSelector,
  d => d.workspace
);
export const workspaceNameSelector = createSelector(
  workspaceSelector,
  d => d.name
);