import React from 'react'
import cls from 'classnames';
import ItemKey from './ItemKey'
import ItemValue from './ItemValue'

export const Wrap = React.memo(({
  children, className, py=5
}) => {
  return (
    <div className={cls(`px-4 py-${py} sm:px-6`, className)}>
      <dl className={`grid grid-cols-1 gap-x-4 gap-y-${parseInt(py)+3} sm:grid-cols-2`}>
        {children}
      </dl>
    </div>
  )
});

export const Item = React.memo(({
  className, name, value, col = 1
}) => {
  return (
    <div className={cls(`sm:col-span-${col}`, className)}>
      <ItemKey>{name}</ItemKey>
      <ItemValue className="mt-1">{value}</ItemValue>
    </div>
  )
});