import React from 'react';
import { SignIn } from 'aws-amplify-react';
import AuthLayout from './Layout';
import SignInForm from './SignInForm';
import IdentityProviders from './IdentityProviders'
import TermsInfo from './TermsInfo'

class MySignIn extends SignIn {

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data, e) {
    this.inputs = {};
    Object.keys(data).forEach(k => {
      this.inputs[k] = data[k];
    });
    this.signIn(e);
  }

  showComponent() {
    const { hide } = this.props;
    if (hide && hide.includes(MySignIn)) { return null; }

    return (
      <AuthLayout
        title="Sign in to your account"
        // description={
        //   <>
        //     Or
        //     <span 
        //       onClick={() => this.changeState(SIGN_UP)}
        //       className="pl-1 cursor-pointer font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
        //     >
        //       start your 14-day free trial
        //     </span>
        //   </>
        // }
      >
        <SignInForm 
          changeState={this.changeState}
          onSubmit={this.handleSubmit}
        />
        <IdentityProviders/>
        <TermsInfo/>
      </AuthLayout>
    )
  }
}

export default MySignIn;