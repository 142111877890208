import { initData } from './table';
import { 
  currencyTpl, currencyShortTpl
} from 'utils/hb';
import React from 'react';

const elseId = 'Everything else';

export default function intuilPieChartData(data, props) {
  let {rows, columns, keys} = initData(data);
  let {dataMap, type, limit = 10, ...rest} = props;

  if (!dataMap) {
    return {
      ...rest,
      data: []
    }
  }

  const label = dataMap.name || keys[0];

  if (dataMap.sub) {
    rows = dataMap.sub.split('.').reduce((r, subKey) => {
      return r.find(el => el[label] === subKey)?.subRows;
    }, rows);
  }

  if (rows[rows.length - 1].isTotal && rows.filter(el => el.isTotal).length === 1) {
    rest.total = rows.pop()?.[dataMap.value];
  }

  rows = rows.map(el => {
    if (el.subRows) {
      const t = el.subRows.filter(el => el.isTotal);
      if (t && t.length === 1) {
        return {
          id: el[label],
          label: el[label],
          value: parseFloat(t[0][dataMap.value])
        }
      }
    }
    return {
      id: el[label],
      label: el[label],
      value: parseFloat(el[dataMap.value])
    }
  });

  if (limit && rows.length > limit) {
    rows = rows.sort((a,b) => b.value - a.value);
    const elseRows = rows.splice(9, rows.length);
    rows.push({
      id: elseId,
      label: elseId,
      value: elseRows.reduce((t,el) => isNaN(el.value) ? t : t + el.value,0)
    });
  }

  const currency = data?.Header?.Currency;
  const rCurrency = (function rcl(cls, def = null){
    return cls.reduce((r, el) => {
      if (el.columns) {
        return rcl(el.columns, r);
      } else if (el.accessor === dataMap.value && el.renderer) {
        switch(el.renderer.type) {
          case "currency":
            return currency;
          default:
            break;
        }
      }
      return r;
    }, def);
  })(columns);

  return {
    ...rest,
    currency,
    sliceLabel: d => (
      <tspan className="text-sm text-cool-gray-600">
        {currencyShortTpl({value: d.value, currency: rCurrency})}
      </tspan>
    ),
    tooltip: d => (
      <span className="flex items-center">
        <span className="w-4 h-4 inline-block mr-2" style={{ backgroundColor: d.color }}></span>
        {d.label}: <strong className="ml-1">{currencyTpl({value: d.value, currency: rCurrency})}</strong>
      </span>
    ),
    fill:[{
      match: {
        id: elseId
      },
      id: 'dots'
    }
    // ,{
    //   match: {
    //     id: 'javascript'
    //   },
    //   id: 'lines'
    // }
    ],
    data: rows
  }
}