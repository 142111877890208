import React from 'react';
import Input from './Input';
import Select from './Select';

function FormItem({
  name, defaultValue, field, ...props
}) {

  const {type, ...rest} = field;

  switch (type) {
    case 'select':
      return (
        <Select
          name={name}
          value={defaultValue}
          {...rest}
          {...props}
        />
      )
    case 'email':
    case 'number':
    case 'text':
      return (
        <Input 
          name={name}
          value={defaultValue}
          type={type}
          {...rest}
          {...props}
        />
      )
    default:
      return null;
  }
}

export default FormItem;