import React from 'react'
import { SIGNED_UP } from 'store/constants'
import PulseLoader from 'components/PulseLoader';
import Auth from '@aws-amplify/auth';

export const AutoSignContext = React.createContext();

function AutoSignIn({children, authState}) {
  const [state, setState] = React.useState(null);

  const isAuto = state && authState === SIGNED_UP;

  React.useEffect(() => {
    if (isAuto) {
      Auth.signIn(state.email, state.password);
    }
  }, [isAuto, state]);

  if (isAuto) {
    return <PulseLoader fullScreen />
  }
  
  return (
    <AutoSignContext.Provider value={setState}>
      { children }
    </AutoSignContext.Provider>
  )
}

export default React.memo(AutoSignIn);