export const apis = [
  'fetch', 'saveEntity'
].reduce((o, n) => {
  o[n] = n;
  const s = `${n}Success`;
  const r = `${n}Error`;
  o[s] = s;
  o[r] = r;
  return o;
},{});

export const apiName = 'api';
export const siteContentApi = "siteContentApi";

// AUTH
export const SIGNED_IN = 'signedIn';
export const SIGNED_OUT = 'signedOut';
export const SIGN_OUT = 'signOut';
export const SIGN_IN = 'signIn';
export const SIGN_UP = 'signUp';
export const SIGNED_UP = 'signedUp';
export const CONFIRM_SIGN_IN = 'confirmSignIn';
export const CONFIRM_SIGN_UP = 'confirmSignUp';
export const FORGOT_PASSWORD = 'forgotPassword';
export const VERIFY_CONTACT = 'verifyContact';
export const REQUIRE_NEW_PASSWORD = 'requireNewPassword';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_ERROR = 'AUTH_ERROR';
export const SET_USER_INFO = 'SET_USER_INFO';
// NOTIFICATION
export const closeNotificationEvent = 'closeNotificationEvent';
export const ERROR = 'ERROR';
export const SUCCESS = 'SUCCESS';
export const WARNING = 'WARNING';
export const DISMISS_ERROR = 'DISMISS_ERROR';
// MESSAGES
export const CLIENT_MESSAGE = 'CLIENT_MESSAGE';
export const SERVER_MESSAGE = 'SERVER_MESSAGE';
export const CLOSE_FORM = 'CLOSE_FORM';
export const SUBMIT_FORM = 'SUBMIT_FORM';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const CLIENT_IMAGE_MESSAGE = 'CLIENT_IMAGE_MESSAGE';
export const CLIENT_IMAGE_MESSAGE_ERROR = 'CLIENT_IMAGE_MESSAGE_ERROR';
export const REFRESH = 'REFRESH';
// CONFIG
export const MESSAGE_LOADRER = 'MESSAGE_LOADRER';
export const WELLCOME_BUTTONS = 'WELLCOME_BUTTONS';
export const SHOW_REFRESH = 'SHOW_REFRESH';
export const SET_WORKSPACE = 'SET_WORKSPACE';
// VARS
export const inputSection = 'inputSection';
export const ioUserMessage = 'userMessage';
export const modalContainerId = 'modal-container';