import React, { Suspense, useMemo } from 'react';
import PulseLoader from 'components/PulseLoader';
import { connect } from 'react-redux';
import {
  connect as ioConnect,
  disconnect
} from 'actions/socket';
import { userIdSelector } from 'store/reducers/auth';
import useInitApp from 'hooks/useInitApp'
const AppLayout = React.lazy(() => import('./AppLayout'));

function App({
  hasUser, dispatch
}) {
  useInitApp(dispatch);

  React.useEffect(() => {
    if (hasUser) {
      dispatch(ioConnect());
      return () => dispatch(disconnect());
    }
  }, [hasUser, dispatch]);

  return useMemo(() => {
    if (!hasUser) {
      return <PulseLoader fullScreen />
    }
    return (
      <Suspense fallback={null}>
        <AppLayout/>
      </Suspense>
    )
  }, [hasUser])
}

const state = st => ({
  hasUser: userIdSelector(st)
});

export default connect(state)(App);