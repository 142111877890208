import { useMemo } from 'react';

export default function useOptions(props) {

  const { options, data } = props;
  const { remoteType } = props.selectProps;

  return useMemo(() => {
    let res = {
      hasParent: false,
      parentName: null
    }

    switch(remoteType) {
      case 'quickbooks': {
        const parentId = data?.ParentRef?.value;
        res.hasParent = parentId ? true : false;
        if (res.hasParent) {
          const parent = options.find(({Id}) => Id === parentId);
          res.parentName = `${data?.IsProject?'Project':'Sub-customer'} of ${parent?.DisplayName}`;
        }
        break;
      }
      default:
        break;
    }

    return res;
  }, [remoteType, data, options]);
}