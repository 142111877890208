import React from 'react';
import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as Text } from './text2.svg';
import cls from 'classnames';

function CompanyLogo({
  className, textClass,
  withText = false,
  size = '10',
  color = 'indigo-600',
  ...props
}) {
  return (
    <i
      className={cls("flex items-center justify-center",
        `text-${color}`, `h-${size}`, className
      )}
      {...props}
    >
      <Logo className="h-full w-auto" />
      {
        withText && <Text className={cls("ml-4 w-auto h-2/3", textClass)} />
      }
    </i>
  )
}

export default React.memo(CompanyLogo);