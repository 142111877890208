import DetailedCell from './DetailedCell';
import DetailedHeader from './DetailedHeader';
import { useMountedLayoutEffect } from "react-table";
import { useMemo } from 'react';

const actions = {
  setDetailedRow: 'setDetailedRow',
  closeDetailedRow: 'closeDetailedRow',
  resetDetailedRow: 'resetDetailedRow'
};

export const columnId = 'detailed';

const detailedColumn = {
  id: columnId,
  Cell: DetailedCell,
  Header: DetailedHeader
};

function reducer(state, action) {
  switch (action.type) {
    case actions.setDetailedRow:
      return {
        ...state,
        detailedRow: action.id,
      };
    case actions.resetDetailedRow:
    case actions.init:
    case actions.closeDetailedRow:
      return {
        ...state,
        detailedRow: null
      };
    default:
      return state;
  }
}

function useInstance(instance) {
  const {
    dispatch,
    rows,
    state: { detailedRow }
  } = instance;

  useMountedLayoutEffect(() => {
    dispatch({ type: actions.resetDetailedRow });
  }, [dispatch]);

  rows.forEach(row => {
    row.isDetailed = detailedRow === row.id;
  });

  const extendInstance = useMemo(() => {
    return {
      setDetailedRow: id => dispatch({ type: actions.setDetailedRow, id }),
      closeDetailedRow: () => dispatch({ type: actions.closeDetailedRow }),
    };
  }, [dispatch]);

  Object.assign(instance, extendInstance);
}

export default function hook(hooks) {
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
  hooks.visibleColumns.push(columns => [
    ...columns,
    detailedColumn
  ]);
}