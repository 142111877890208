import {
  intuilData
} from './intuit';
import { useMemo } from 'react';

function findType(data) {
  if (data.Header) {
    if (
      data.Header.SummarizeColumnsBy
      || data.Header.ReportName
    ) return 'intuit';
  }
  return null;
}

function getDataType(data) {
  let type = findType(data), 
    options = {};
  if (!type) {
    const types = data.map(findType);
    if (types[0] && types.every(t => t===types[0])) {
      type = types[0];
    }
  }
  return {type, options};
}

export function dataParser(data, opt = {}) {
  const {type, options} = getDataType(data)
  if (data) switch (type) {
    case 'intuit':
      return intuilData(data, {...options,...opt});
    default:
      break;
  }
  return {data, ...opt};
}

export function useDataParser(data, options) {
  return useMemo(() => dataParser(data, options), [data, options]);
}

export function useChartDataParser(data, options = {}) {
  return useMemo(() => dataParser(data, {
    ...options,
    type: 'chart'
  }), [data, options]);
}

export function usePieChartDataParser(props = {}) {
  return useMemo(() => dataParser(props.data, {
    ...props,
    type: 'pieChart'
  }), [props]);
}