import React from 'react';
import Notifications from 'containers/Notifications';
import Authenticator from 'containers/Authenticator';

function AppContainer() {
	return (
		<React.Fragment>
			<Notifications />
			<Authenticator />
		</React.Fragment>
	);
}

export default React.memo(AppContainer);