import React from 'react';
import { components } from 'react-select';
import cls from 'classnames';
import Icon from 'components/Icon';
import useOptions from './hooks/useOptions';

export const optionStyles = base => ({
});

function Option(props) {
  const {
    isFocused, isSelected, label,
    isDisabled, value
  } = props;
  const { renderValues } = props.selectProps;
  const {
    parentName, hasParent
  } = useOptions(props);
  const {icon, helpLabel, inlineIcon} = props.data;

  if (props.data?.component) {
    return <props.data.component {...props}/>
  }

  return (
    <components.Option {...props}>
      <div className={cls("cursor-default select-none relative py-2 pl-3 pr-9", {
        'text-white bg-indigo-600': isFocused && !isDisabled,
        'text-gray-900': !isFocused && !isDisabled,
        'text-gray-400': isDisabled
      })}>
        <div className="flex items-center space-x-2">
          {icon && <Icon 
            className={cls("h-5 w-5", {
              'text-gray-700': !isFocused && !isDisabled,
            })} 
            name={icon} 
          />}
          {
            inlineIcon && <span>{inlineIcon}</span>
          }
          <span className={cls('truncate', {
            'font-normal': !isSelected,
            'font-semibold': isSelected,
            'pl-4': hasParent
          })}>
            {label}
          </span>
          {(renderValues || parentName || helpLabel) && <span className={cls("truncate", {
            'text-gray-500': !isFocused,
            'text-indigo-200': isFocused,

          })}>
            {renderValues ? value : (parentName || helpLabel)}
          </span>}
        </div>
        {isSelected && <span className={cls("absolute inset-y-0 right-0 flex items-center pr-4", {
          'text-white': isFocused,
          'text-indigo-600': !isFocused
        })}>
          <Icon className="h-5 w-5" name="s-check" />
        </span>}
      </div>
    </components.Option>
  );
};

export default Option;