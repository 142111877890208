import React from 'react';
import Button from 'components/Button';
import { SlideoverContext } from 'components/Slideover';

function FormButtons({
  onSubmit
}) {
  const close = React.useContext(SlideoverContext);
  const _onSubmit = React.useCallback(() => {
    onSubmit && onSubmit(close);
  }, [onSubmit, close]);
  return (
    <div className="w-full px-4 py-4 space-x-4 flex justify-end">
      <Button onClick={close}>Cancel</Button>
      <Button primary onClick={_onSubmit}>Save</Button>
    </div>
  )
}

export default React.memo(FormButtons);