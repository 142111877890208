import React from 'react'
import Storage from '@aws-amplify/storage'
import Icon from 'components/Icon'

export function downloadBlob(blob, filename) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
}

function FileLink({
  file,
  nameAsLabel = false
}) {
  const [loading, setLoading] = React.useState(false);
  const onClick = React.useCallback(() => {
    setLoading(true);
    Storage.get(file, { 
      level: 'private',
      download: true 
    }).then(res => downloadBlob(res.Body, file))
    .finally(() => setLoading(false))
  }, [file]);

  return (
    <span
      onClick={onClick}
      href="/"
      className="cursor-pointer font-medium text-indigo-600 hover:text-indigo-500 flex items-center"
    >
      {loading ? <Icon
        name="oval"
        className="mr-1 h-4 w-4"
      /> : null}
      {nameAsLabel ? file : 'Download'}
    </span>
  )
}

export default React.memo(FileLink);