import axios from 'axios';
import { useQuery } from 'react-query'

const endpoint = 'https://hala.ai/ghost/api/v3/content';
const key = "9ad48ba162da2ce883c11acdc3";

export default async function siteContent(method, url, params = {}) {
  params.params = params.params || {};
  params.params.key = key;
  return axios[method](`${endpoint}${url}`, params)
  .then(r => r.data);
}

function getPost({queryKey}) {
  const [id] = queryKey;
  if (!id) return {};
  return siteContent('get', `/posts/${id}`, {
    params: {
      fields: "title,html,custom_excerpt"
    }
  })
  .then(r => r.posts[0]);
}

export function useSkillDetails(id) {
  return useQuery([id, 'hl-content/posts'], getPost)
}