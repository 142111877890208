import React from 'react';
import classnames from 'classnames';
import { 
  defInputClass, inputTextSmClass,
  inputErrClass
} from "./index";

function NativeSelect({
    classNames,
    options,
    placeholder,
    valPreffix,
    valSuffix,
    error,
    ...props
}) {
    return (
        <select
            {...props}
            className={classnames("form-select", 
              defInputClass, classNames, inputTextSmClass, {
                'cursor-not-allowed': props.disabled,
                'text-gray-400': props.disabled,
                'pr-10': error,
                [inputErrClass]: error
            })}
        >
            {placeholder && <option className="text-gray-400" value="" disabled>{placeholder}</option>}
            {
                options && options.map((o,k) => (
                    <option key={k} value={o}>
                        {`${valPreffix ? `${valPreffix} ` : ''}${o}${valSuffix ? ` ${valSuffix}` : ''}`}
                    </option>
                ))
            }
        </select>
    )
}

export default NativeSelect;