import React from 'react';
import { ForgotPassword } from 'aws-amplify-react';
import AuthLayout from './Layout';
import Button from '../Button';
import Input from '../FormElements/Input';
import FormItem from '../FormElements/FormItem';

class MyForgotPassword extends ForgotPassword {

	back = e => {
		e.preventDefault();
		this.changeState('signIn');
	}

	onSubmit = e => {
		e.preventDefault();
		if (this.state.delivery) {
			this.submit(e);
		} else this.send(e);
	}

	onResend = e => {
		e.preventDefault();
		this.send(e);
	}

	sendView() {
		return (
			<AuthLayout
				title="Reset your password"
				description="Please enter your email address associated with your account and we will email you a verification code to set a new password"
			>
				<form className="mt-8" onSubmit={this.onSubmit}>
					<Input
						aria-label="Email address"
						placeholder="Email address"
						name="username"
						type="email"
						required
						onChange={this.handleInputChange}
					/>
					<div className="mt-10 flex items-center justify-between">
						<Button type="submit">
							Send Verification Code
						</Button>
						<div className="text-sm leading-5">
							<a href="/"
								onClick={this.back}
								className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
							>
								Back to Sign In
          	</a>
						</div>
					</div>
				</form>
			</AuthLayout>
		)
	}

	submitView() {
		return (
			<AuthLayout
				title="Reset your password"
				description={(
					<>
						Complete password reset for account associated with <strong>{this.inputs.username}</strong> e-mail.
					</>
				)}
			>
				<form className="mt-8" onSubmit={this.onSubmit}>
					<FormItem
						label="Verification code"
						description="Your verification code that has been sent on your email"
					>
						<Input
							name="code"
							required
							onChange={this.handleInputChange}
						/>
					</FormItem>
					<FormItem className="mt-4" label="New password">
						<Input
							name="password" 
							type="password"
							required
							autoComplete="new-password"
							onChange={this.handleInputChange}
						/>
					</FormItem>
					<div className="mt-10 flex items-center justify-between">
						<Button type="submit">
							Submit
						</Button>
						<div className="text-sm leading-5">
							<a href="/"
								onClick={this.onResend}
								className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
							>
								Resend Code
          		</a>
						</div>
					</div>
				</form>
			</AuthLayout>
		)
	}

	showComponent() {
		const { hide, authData = {} } = this.props;
		if (hide && hide.includes(MyForgotPassword)) { return null; }
		return this.state.delivery || authData.username ? this.submitView() : this.sendView();
	}
}

export default MyForgotPassword;