import React from 'react';
import cls from 'classnames';

function SizeContainer({
  size = 'full', 
  className, 
  children,
  ...props
}, ref) {

  const _sCls = React.useMemo(() => {
    if (size === 'sm') {
      return 'max-w-3xl';
    } else if (size !== 'full') {
      return `max-w-screen-${size}`;
    }
    return '';
  }, [size]);

  return (
    <div 
      ref={ref}
      className={cls('w-full mx-auto', _sCls, className)}
      {...props}
    >
      {children}
    </div>
  )
}

export default React.forwardRef(SizeContainer);