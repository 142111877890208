import React from 'react';
import RowDropdown from 'components/Dropdown/BtnIcon';
import RowForm from './RowForm';
import { TableContext } from '../../Beta';
import CellLoader from '../../CellLoader'

function ActionsCell({
  onChange, setEditRow, 
  closeEditRow,
  row, isLoading
}) {
  const {id, isEdit, isDelete, original} = row;
  const { setOverflow } = React.useContext(TableContext);
  const [duplicate, setDuplicate] = React.useState(false);

  const onDropdownChange = React.useCallback(isOpen => {
    setOverflow && setOverflow(!isOpen);
  }, [setOverflow]);

  const onClose = React.useCallback(() => closeEditRow(), [closeEditRow]);
  const onSubmit = React.useCallback(data => {
    let type = "edit";
    if (duplicate) {
      type = "add";
      setDuplicate(false);
    }
    onChange({type, data, id});
  }, [onChange, id, duplicate]);
  const items = React.useMemo(() => {
    return [{
      name: "Edit",
      icon: "s-pencil-alt",
      onClick: () => setEditRow(id)
    }, {
      name: "Duplicate",
      icon: "s-duplicate",
      onClick: () => {
        setDuplicate(true)
        setEditRow(id)
      }
    },{
      border: true,
      name: "Delete",
      icon: "s-trash",
      onClick: () => onChange({
        type: 'remove',
        id
      })
    }]
  }, [setEditRow, onChange, id]);

  const undoDelete = React.useCallback(() => {
    onChange({
      type: 'undoRemove',
      id
    });
  }, [onChange, id]);

  if (isDelete) {
    return (
      <div className="relative w-8 h-8 flex items-center">
        <div 
          onClick={undoDelete} 
          className="absolute text-sm font-medium text-indigo-600 hover:text-indigo-900 w-24 text-right right-0 transform translate-x-6 cursor-pointer"
        >Undo Delete</div>
      </div>
    )
  }

  if (isLoading) {
    return <CellLoader/>
  }

  return (
    <>
      <RowDropdown 
        items={items}
        icon="s-dots-vertical"
        onChange={onDropdownChange}
      />
      {
        isEdit && <RowForm 
          onClose={onClose}
          onSubmit={onSubmit}
          values={original}
        />
      }
    </>
  ) 
}

export default React.memo(ActionsCell)