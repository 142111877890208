import React from 'react';

function FormFieldset({
  className, label, description,
  error, children
}, ref) {
  return (
    <fieldset ref={ref} className={className}>
      { label && <legend className="text-base font-medium text-gray-900">
          { label }
        </legend> 
      }
      {
        description && <p className="text-sm leading-5 text-gray-500">
          { description }
        </p>
      }
      {
        error && <p className="text-sm leading-5 text-red-600">
          { error }
        </p>
      }
      <div className={label || description ? "mt-4 space-y-4" : 'space-y-4'}>
        { children }
      </div>
    </fieldset>
  )
}

export default React.forwardRef(FormFieldset);