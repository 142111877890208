import React from 'react'
import cls from 'classnames';
import ItemKey from './ItemKey'
import ItemValue from './ItemValue'

export const Wrap = React.memo(({
  children, className
}) => {
  return (
    <div className={className}>
      <dl>
        {children}
      </dl>
    </div>
  )
});

export const Item = React.memo(({
  isOdd, className, name, value, py = 5
}) => {
  return (
    <div
      className={cls(`px-4 py-${py} sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6`, {
        "bg-gray-50": !isOdd,
        "bg-white": isOdd
      }, className)}
    >
      <ItemKey>{name}</ItemKey>
      <ItemValue className="mt-1 sm:mt-0 sm:col-span-2">{value}</ItemValue>
    </div>
  )
});