import moment from 'moment';

function initColumns(cols, header, keys = []) {
  const columns = cols.map(col => {
    const key = col.ColTitle || col.ColType;
    let i = {
      Header: key
    };
    if (col.Columns) {
      const res = initColumns(col.Columns.Column, header, keys);
      i.columns = res.columns;
      keys = res.keys;
    } else {
      keys.push(key);
      i.accessor = key;

      let ColKey = col.MetaData && col.MetaData.find(({Name}) => Name==='ColKey');
      ColKey = ColKey ? ColKey.Value : null;

      switch(ColKey) {
        case 'Quantity':
          i.renderer = {
            type: 'number'
          }
          break;
        case 'GrossMarginPerc':
        case 'PercentSales':
          i.renderer = {
            type: 'percent'
          }
          break;
        default:
          break;
      }

      if (!i.renderer) switch(col.ColType) {
        case "Money":
          i.renderer = {
            type: "currency",
            currency: header.Currency
          }
          break;
        default:
          break;
      }
    }
    return i;
  });
  return {columns, keys};
}

function genItem(data, keys, def = {}) {
  return data.reduce((item, {value}, key) => {
    if (typeof value !== 'undefined') item[keys[key]] = value;
    // else if (item.isTotal) {
    //   item[keys[key]] = '0';
    // }
    return item;
  }, def);
}

function initRows(rows, keys) {
  if (!rows) return [];
  return rows.reduce((cl, el) => {
    let item = null;
    if (el.Header) {
      item = genItem(el.Header.ColData, keys);
      item.subRows = [];
      if (el.Rows) {
        item.subRows = initRows(el.Rows.Row, keys);
      }
      if (el.Summary) {
        item.subRows.push(genItem(el.Summary.ColData, keys, {isTotal: true}))
      }
    } else if (el.Summary) {
      item = genItem(el.Summary.ColData, keys, {isTotal: true});
    } else if (el.ColData) {
      item = genItem(
        el.ColData, keys, 
        el.group === "GrandTotal" ? {isTotal: true} : {}
      );
    }
    if (item) cl.push(item);
    return cl;
  }, []);
}

function recSome(els, cb) {
  return els.some(el => {
    if (el.columns) {
      return recSome(el.columns, cb);
    }
    return cb(el);
  });
}

export function initData(data) {
  let {columns, keys} = initColumns(data.Columns.Column, data.Header);
  const rows = initRows(data.Rows.Row, keys);
  return {columns, rows, keys};
}

function _keysName(el, keys, isToCompare = false) {
  keys.forEach((n,k) => {
    if (k === 0) return;
    const k1 = `${n}0`;
    const k2 = `${n}1`;
    el[k1] = !isToCompare ? (el[n] || (el.isTotal ? '0' : '')) : (el.isTotal ? '0' : '');
    el[k2] = isToCompare ? (el[n] || (el.isTotal ? '0' : '')) : (el.isTotal ? '0' : '');
    el.change = (parseFloat(el[k1] || '0') - parseFloat(el[k2] || '0')).toString();
    if (el.change === '0' && !el.isTotal) {
      el.change = '';
    }
    el.change_perc = (parseFloat(el.change) / (Math.abs(parseFloat(el[k2])) / 100)).toFixed(2).toString();
    if (el.change_perc.includes('Infinity') || isNaN(el.change_perc)) {
      el.change_perc = el.isTotal ? '0.00' : '';
    } 
    delete el[n];
  });
  if (el.subRows) {
    el.subRows = el.subRows.map(subEl => _keysName(subEl, keys, isToCompare))
  }
  return el;
}

function _initDataMap(rows1, rows2, keys) {
  const nkey = keys[0];
  let rows = [];
  let done = false
  while (!done) {
    const el1 = rows1[0];
    const el2 = rows2[0];
    if (!el1 || !el2) {
      done = true;
      continue;
    }
    if (el1[nkey] === el2[nkey]) {
      const _el1 = rows1.shift();
      const _el2 = rows2.shift();
      let nEl = {
        [nkey]: el1[nkey],
        isTotal: _el1.isTotal || _el2.isTotal
      }
      keys.forEach((n,k) => {
        if (k === 0) return;
        let _k1 = `${n}0`;
        let _k2 = `${n}1`;
        nEl[_k1] = _el1[n] || (nEl.isTotal ? '0' : '');
        nEl[_k2] = _el2[n] || (nEl.isTotal ? '0' : '');
        nEl.change = (parseFloat(nEl[_k1] || '0') - parseFloat(nEl[_k2] || '0')).toString();
        if (nEl.change === '0' && !nEl.isTotal) {
          nEl.change = '';
        } 
        nEl.change_perc = (parseFloat(nEl.change) / (Math.abs(parseFloat(nEl[_k2])) / 100)).toFixed(2).toString();
        if (nEl.change_perc.includes('Infinity') || isNaN(nEl.change_perc)) {
          nEl.change_perc = nEl.isTotal ? '0.00' : '';
        }  
      });
      if (el1.subRows && el2.subRows) {
        nEl.subRows = _initDataMap(el1.subRows, el2.subRows, keys);
      }
      rows.push(nEl);
    } else {
      if (!rows1.find(el => el[nkey] === el2[nkey])) {
        rows.push(
          _keysName(rows2.shift(), keys, true)
        );
      }
      if (!rows2.find(el => el[nkey] === el1[nkey])) {
        rows.push(
          _keysName(rows1.shift(), keys)
        );
      }
    }
  }
  return rows;
}

function initDataMap(data, options = {}) {
  let currency = null;
  const res = data.reduce((res, el, dk) => {
    const { StartPeriod, EndPeriod } = el.Header;
    if (!currency) {
      currency = el.Header.Currency;
    }
    const { rows, columns, keys } = initData(el);
    if (!res) {
      res = {rows};
    } else {
      res.rows = _initDataMap(res.rows, rows, keys);
    }
    if (!res.columns) {
      res.columns = [columns[0]];
    }
    columns.forEach((el,k) => {
      if (k > 0) {  
        res.columns.push({
          ...el,
          Header: moment(StartPeriod).twix(EndPeriod, {allDay: true}).format({implicitYear: false}),
          accessor: `${el.accessor}${dk}`
        });
      } 
    });
    return res;
  }, null);

  if (options.compare) {
    res.columns.push({
      renderer: {type: "currency", currency},
      Header: `Change`,
      accessor: `change`
    },{
      renderer: {type: "percent"},
      Header: `% Change`,
      accessor: `change_perc`
    });
  }

  return res;
}

export default function intuilTableData(data, options = {}) {

  let { columns, rows } = Array.isArray(data) 
    ? initDataMap(data, options)
    : initData(data);

  if (rows.length === 1 && rows[0].isTotal && Object.keys(rows[0]).length <= 2) {
    rows = [];
  }

  if (options.columns) {
    columns = options.columns.map(col => {
      recSome(columns, el => {
        if (el.accessor === col.accessor) {
          col = {
            ...el,
            ...col
          }
          return true;
        }
        return false;
      })
      return col;
    })
  }
  return {columns, data:rows};
}