import React from 'react'
import cls from 'classnames';
import ItemKey from './ItemKey'
import ItemValue from './ItemValue'

export const Wrap = React.memo(({
  children, className
}) => {
  return (
    <div className={cls("px-4 py-5 sm:p-0", className)}>
      <dl>
        {children}
      </dl>
    </div>
  )
});

export const Item = React.memo(({
  isFirst, className, name, 
  value, py='5'
}) => {
  return (
    <div
      className={cls(`sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-${py}`, {
        "mt-8 sm:mt-0 sm:border-t sm:border-gray-200": !isFirst
      }, className)}
    >
      <ItemKey>{name}</ItemKey>
      <ItemValue className="mt-1 sm:mt-0 sm:col-span-2">{value}</ItemValue>
    </div>
  )
});