import React from 'react'
import Button from 'components/Button'
import cls from 'classnames';

function FormActions({
  onCancel, border = true, loading, fixed
}) {
  return (
    <div className={cls("w-full space-x-4 flex justify-end", {
      "border-t border-gray-200": border,
      "mt-8 pt-5": !fixed && border,
      "p-3 sm:px-6 flex-shrink-0 flex": fixed
    })}>
      {onCancel && <Button onClick={onCancel}>Cancel</Button>}
      <Button 
        type="submit"
        loading={loading}
      >
        Save
      </Button>
    </div>
  )
}

export default React.memo(FormActions);