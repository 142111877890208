import React from 'react';
import Icon from 'components/Icon';

function HeaderSorted({
  isSorted, isDesc
}) {

  const name = isSorted
    ? isDesc
      ? 'o-chevron-down'
      : 'o-chevron-up'
    : 'o-selector';

  return (
    <Icon className="w-4 h-4 inline-flex" name={name}/>
  )
}

export default React.memo(HeaderSorted);