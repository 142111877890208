import React from 'react';
import Icon from 'components/Icon';
import Dropdown from './index';
import cls from 'classnames';

function BtnIconDropdown({ 
  icon, size = 5, iconClass, 
  color = "text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 hover:text-gray-500 focus:text-gray-500 focus:bg-gray-100",
  ...props
}) {
  const Toggle = React.useMemo(() => (
    <button
      type="button"
      className={cls("inline-flex items-center justify-center rounded-full bg-transparent focus:outline-none transition ease-in-out duration-150", {
        [`w-${size + 3} h-${size + 3}`]: size

      }, color)}
    >
      <Icon
        className={cls({
          [`w-${size} h-${size}`]: size
        }, iconClass)}
        name={icon}
      />
    </button>
  ), [icon, size, iconClass, color]);

  return (
    <Dropdown
      Toggle={Toggle}
      {...props}
    />
  )
}

export default React.memo(BtnIconDropdown);