import React from 'react';
import { components } from 'react-select';
// import { menuWrapClass } from 'components/Menu/MenuWrap';

// const menuWrapClass = "z-20 relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8";

export const menuListStyles = base => ({
});

function menuList(props) {
    return (
        <components.MenuList {...props} 
            className="max-h-60 overflow-auto"
        >
            { props.children }
        </components.MenuList>
    );
};

export default menuList;