import React from 'react';
// import cls from 'classnames';
// import { useFormItemError } from 'hooks/useFormItemError';

function FormItemWrap({
  children, name
}, ref) {
  // const {error} = useFormItemError(name);
  
  // console.log('!!!!! error', error)

  return (
    <div ref={ref} 
      // className={cls("px-2 py-1.5 border border-indigo-300 shadow-outline relative")}
    >
      {children}
    </div>
  )
}

export default React.memo(React.forwardRef(FormItemWrap));