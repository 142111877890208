import React, { useEffect, useCallback } from 'react';
import SizeContainer from 'components/SizeContainer';
import { useFormContext } from "react-hook-form";
import Table from 'components/Table/Beta';
import { compile } from 'utils/hb';
import { warningNotification } from 'utils/notifications'
import { sendMessage } from 'actions/input';
import { closeNotification } from 'actions/notifications'
import { connect } from 'react-redux';

function FormTableItem({
  size, name, validationOptions,
  type, label, description,
  rowSelection, data = [], 
  sendMessage, closeNotification,
  currency,
  ...props
}) {
  const { register, unregister, watch, setValue } = useFormContext();
  const fields = rowSelection ? data : watch(name);

  useEffect(() => {
    register({ name }, validationOptions);
    return () => unregister(name);
  }, [name, register, unregister, validationOptions]);

  const { columns } = props;
  const preChange = React.useMemo(() => {
    const opts = columns.reduce((o, el) => {
      if (el?.fieldOptions?.onChange) {
        if (!o) o = {};
        const id = el.id || el.accessor;
        o[id] = Object.keys(el.fieldOptions.onChange).map(key => ({
          key,
          val: compile(el.fieldOptions.onChange[key])
        }))
      }
      return o;
    }, null);
    if (!opts) return null;
    return (prev, next) => {
      let n = {
        ...prev,
        ...next
      };
      try {
        Object.keys(next).forEach(k => {
          opts[k] && opts[k].forEach(el => {
            n[el.key] = el.val(n);
          })
        })
      } catch(err){
        console.log(err);
      }
      return n;
    };
  }, [columns]);

  const onClickWriteOff = useCallback(() => {
    closeNotification()
    sendMessage('Asset write-off')
  }, [sendMessage, closeNotification]);

  const onChange = useCallback(e => {
    let { type, id, data } = e || {};
    id = parseInt(id);
    switch (type) {
      case 'select':
        setValue(name, data);
        break;
      case 'edit':
        setValue(name, [...fields.map((el, key) => {
          if (key === id) {
            if (el.__action !== 'add') {
              data.__action = 'edit';
            }
            if (preChange) {
              return preChange(el, data);
            }
            return {
              ...el,
              ...data
            };
          }
          return el;
        })]);
        break;
      case 'undoRemove':
        setValue(name, [...fields.map((el, key) => {
          if (key === id) {
            const nEl = {
              ...el,
              __action: 'undoRemove'
            };
            delete nEl.delete;
            return nEl;
          }
          return el;
        })]);
        break;
      case 'remove':
        const hasPostedEls = fields.find((el, key) => 
          key === id && el.postedAmount > 0 && el.remainAmount > 0
        );
        if (hasPostedEls) {
          warningNotification({
            title: "You are going to delete the asset that has balance.",
            description: (
              <>
                <span>At first, you need to write-off the asset, and then you can delete it. To write-off the asset, you would need to execute the skill</span>
                <span onClick={onClickWriteOff} className="bg-white ml-1 cursor-pointer rounded-md text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Asset write-off</span>.
                <br/>
                <div className="py-3 text-sm font-medium text-gray-800">
                  <div>Balance - {hasPostedEls.remainAmount} {currency}</div>
                  <div className="pt-1">Write-off account - {hasPostedEls.accountWriteOff.name}</div>
                </div>
              </>
            ),
            timeout: 10000,
            size: 'md'
          })
        } else setValue(name, [...fields.map((el, key) => {
          if (key === id) {
            return {
              ...el,
              __action: 'delete'
            };
          }
          return el;
        })]);
        break;
      case 'add': {
        data = Array.isArray(data) ? data : [data]
        data = data.map(el => ({
          ...el,
          __action: 'add'
        }))
        if (isNaN(id)) {
          setValue(name, [...fields || [], ...data]);
        } else {
          const nFields = [...fields];
          data.forEach((el,k) => {
            nFields.splice(id + k, 0, el);
          })
          setValue(name, nFields);
        }
        break;
      }
      default:
        break;
    }
    // clearErrors(name); removed due to bug with select event
  }, [fields, setValue, name, preChange, onClickWriteOff, currency]);

  return (
    <SizeContainer size={size} className="bg-white">
      <Table
        data={fields}
        rowSelection={rowSelection}
        onChange={onChange}
        {...props}
      />
    </SizeContainer>
  );
}

export default connect(
  null,
  {
    sendMessage, closeNotification
  }
)(FormTableItem);