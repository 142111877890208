import React from 'react';
import RadioInput from 'components/FormElements/Radio';
import { useFormItemError } from 'hooks/useFormItemError';
import { Controller, useFormContext } from "react-hook-form";

function RadioButtonInput({
  name, value, 
  validationOptions, 
  ...props
}) {
  const { control } = useFormContext();
  const { ref, error } = useFormItemError(name);
  value = value || undefined;

  return (
    <Controller
      as={<RadioInput {...props} ref={ref} error={error}/>}
      name={name}
      defaultValue={value}
      control={control}
      rules={validationOptions}
    />
  );
}

export default React.memo(RadioButtonInput);