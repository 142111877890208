import { memo, forwardRef } from 'react';
import NativeSelect from './NativeSelect';
import RSelect from './RSelect';

function Select({
    native,
    ...props
}, ref) {
  if (native) {
    return <NativeSelect {...props} />;
  }
  return <RSelect {...props} />;
}

export default memo(forwardRef(Select));