import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import {
  SIGNED_OUT,
  AUTH_LOADING,
  AUTH_ERROR,
  SIGNED_IN,
  SIGN_IN,
  SET_USER_INFO,
  SIGN_OUT
} from 'store/constants';

export const authStatusChanged = (type, authData) => ({
  type,
  authData
});

export const signedIn = authData => ({
  type: SIGNED_IN,
  authData
});

export const signIn = () => ({
  type: SIGN_IN
});

export const signOut = () => {
  return async dispatch => {
    Hub.dispatch(SIGN_OUT)
    await Auth.signOut()
      .then(() => Hub.dispatch(SIGNED_OUT));
    dispatch({
      type: SIGNED_OUT
    });
  };
};

export const authError = payload => ({
  type: AUTH_ERROR,
  payload
})

export const authLoading = (authData) => ({
  type: AUTH_LOADING,
  authData
});

export async function refreshToken() {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const currentSession = await Auth.currentSession();
  return new Promise((resolve, reject) => {
    cognitoUser.refreshSession(currentSession.refreshToken, err => {
      if (err) reject(err);
      else resolve();
    });
  });
}

const query = new URLSearchParams(window.location.search);
export const qEmail = query.get('email');
export const qIdentity = query.get('identity');

export const setCurrentUser = authData => {
  return dispatch => {
    if (qEmail && authData.email !== qEmail) {
      dispatch(signOut());
      return;
    }
    dispatch({
      type: SET_USER_INFO,
      authData
    })
  }
}