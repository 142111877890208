import React, { memo, Suspense, lazy } from 'react';
import Input from './Input';
import Select from './Select';
import DatePicker from './DatePicker';
import Checkbox from './Checkbox';
import RadioButton from './RadioButton';
import LongText from './LongText';
import Table from './Table';
import FormItem from './FormItem';
import ConditionalItem from './ConditionalItem';
import FormTitle from "./FormTitle";
import FormHr from "./FormHr";
import FormSection from "./FormSection";
import cls from 'classnames';
import Fieldset from './Fieldset';
import FormHeader from 'components/Layout/Header'

const DetailedAddress = lazy(() => import('./DetailedAddress/index'))
const CreditCard = lazy(() => import('./CreditCard/index'))

function FormItems({ items, withHeader }) {
  withHeader = withHeader || items.some(({type}) => type === 'header');

  return items.map((field, key) => {
    const { type } = field;
    let Comp = null;

    const {
      label, description,
      col = 6, className,
      items: subItems = [], grid = true,
      ...rest
    } = field;

    const _cls = cls(className, {
      [`sm:col-span-${col}`]: col && !className,
      "px-4 sm:px-6": withHeader
    });

    switch (type) {
      case 'creditCard':
        return (
          <Suspense key={key} fallback={null}>
            <CreditCard
              {...field}
            />
          </Suspense>
        )
      case 'detailedAddress':
        return (
          <Suspense key={key} fallback={null}>
            <DetailedAddress
              {...field}
            />
          </Suspense>
        )
      case 'fieldset':
        const _subItems = subItems.map((el => {
          return {
            ...el
            // className: cls(el.className, 'mt-4')
          };
        }));
        return (
          <Fieldset
            className={_cls}
            key={key}
            label={label}
            name={rest.name}
            description={description}
          >
            <FormItems items={_subItems} />
          </Fieldset>
        );
      case 'toggle':
      case 'checkbox':
        return <Checkbox
          key={key}
          {...field}
        />;
      case 'radio':
        return <RadioButton
          key={key}
          {...field}
          className={_cls}
        />;
      case 'longText':
        Comp = LongText;
        break;
      case 'date':
        Comp = DatePicker;
        break;
      case 'select':
        Comp = Select;
        break;
      case 'hidden':
      case 'text':
      case 'email':
      case 'password':
      case 'number':
        Comp = Input;
        break;
      case 'tags': {
        rest.creatable = true;
        rest.isMulti = true;
        Comp = Select;
        break;
      }
      case 'table':
        return <Table key={key} {...field}/>
      case 'condition':
        return <ConditionalItem key={key} {...field} />;
      case 'title':
        return <FormTitle 
          key={key} 
          className={className}
          {...field}
        />;
      case 'header':
        return <FormHeader 
          key={key} 
          {...field}
        />;
      case 'hr':
        return <FormHr key={key} {...field} />;
      case 'section':
        return (
          <FormSection 
            key={key} 
            grid={grid} 
            className={className} 
            {...rest}
          >
            {subItems && <FormItems withHeader={withHeader} items={subItems} />}
          </FormSection>
        );
      default:
        return null;
    }

    return (
      <FormItem
        className={_cls}
        key={key}
        label={label}
        description={description}
        {...rest}
      >
        <Comp {...rest} />
      </FormItem>
    )
  });
}

export default memo(FormItems);