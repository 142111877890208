import { useRef, useEffect, useCallback } from 'react';

export default function useOnClickOutside(cb, ref) {
  const _ref = useRef();
  ref = ref || _ref;

  const handleClickOutside = useCallback(e => {
    if (ref.current && !ref.current.contains(e.target)) cb(e);
  }, [cb, ref]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);

  return ref;
}