import React from 'react';
import Slideover from 'components/Slideover';
import DetailedView from 'components/DescriptionList';
import { columnId } from './index';
import { TableContext } from '../../Beta';

function DetailedCell(props) {
  const { id, isDetailed, allCells, original } = props.row;
  const { isTotal = false } = original;
  const isHide = isTotal || (original.subRows && Object.keys(original).filter(key => {
    if (key !== 'subRows' && original[key]) {
      return true;
    }
    return false;
  }).length <= 1);

  const {
    setDetailedRow, closeDetailedRow
  } = props;
  let {
    slideover = {}
  } = React.useContext(TableContext)

  const onClick = React.useCallback(() => setDetailedRow(id), [setDetailedRow, id]);
  const onClose = React.useCallback(() => closeDetailedRow(), [closeDetailedRow])

  const values = React.useMemo(() => {
    if (isDetailed) {
      let d = allCells
        .filter(({ column: { id } }) => ![columnId, 'selection'].includes(id));
      return d.map(({ render, row, column }) => {
        let name = null;
        if (typeof row.canExpand !== 'undefined' && column.value) {
          name = column.value;
        } 
        if (!name) {
          name = render('Header');
        }
        return {
          name,
          value: render('Cell')
        }
      })
    }
    return [];
  }, [isDetailed, allCells]);

  if (isHide) {
    return null;
  }

  return (
    <>
      <div
        onClick={onClick}
        className="text-right text-indigo-600 hover:text-indigo-900 font-medium cursor-pointer"
      >
        Details
      </div>
      {
        isDetailed
          ? <Slideover onClose={onClose} {...slideover}>
              <DetailedView
                values={values}
                title="Details"
                description="Detailed information by table row"
              />
            </Slideover>
          : null
      }
    </>
  )
}

export default React.memo(DetailedCell)