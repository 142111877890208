import React from 'react';
import { components } from 'react-select';

export const multiValueRemoveStyles = base => ({
});

function MultiValueRemove({innerProps, ...props}) {

    const {className, ..._innerProps} = innerProps;

    return (
        <components.MultiValueRemove innerProps={_innerProps} {...props}>
            <button type="button" className="flex-shrink-0 -mr-0.5 ml-1.5 inline-flex text-indigo-500 focus:outline-none focus:text-indigo-700">
                <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                    <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7"/>
                </svg>
            </button>
        </components.MultiValueRemove>
    );
};

export default MultiValueRemove;