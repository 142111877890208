import { useMemo } from 'react';
import { dataParser } from 'utils/parser';
import {
  useExpanded, useRowSelect, useSortBy
} from 'react-table';
import useStyle from './useStyle';
import Cell from '../DefaultCell';
import ExpandedCell from '../ExpandedCell';
import EditableCell from './useEditable/EditableCell';
import useExpandedColumn from './useExpandedColumn';
import useRowSelectExtend from './useRowSelectExtend';
import useRowDetail from './useRowDetail';
import useRowEdit from './useRowEdit';
import useContext from './useContext';
import useEditable from './useEditable';
import RowWrap from '../RowWrap';
import EditableEmptyCell from './useEditable/EmptyCell';
import useFooter from './useFooter';
import FooterCell from '../Footer/Cell'
import FooterTotal from '../Footer/Total'
import useExport from './useExport'

const defaultColumn = {
  Cell,
  FooterTotal,
  FooterCell,
  ExpandedCell,
  EditableCell,
  EditableEmptyCell
};

export default function useTableOptions(props) {
  const options = useMemo(() => {
    const columns = props.columns && props.columns.map(col => {
      let ext = {...col};
      if (ext.render) {
        ext.renderer = ext.render;
        delete ext.render;
      }
      return ext;
    });
    const parsed = dataParser(props.data || [], {
      ...props,
      columns: columns,
      type: 'table'
    });
    let rowWrap = RowWrap;

    const hasExpanded = parsed.data && parsed.data.some(el => el.subRows && el.subRows.length ? true : false);

    let _hiddenColumns = props.hiddenColumns || [];
    if (_hiddenColumns && _hiddenColumns.length) {
      const _columns = columns || parsed?.columns || [];
      if (!_columns || !_columns.length) {
        _hiddenColumns = [];
      } else {
        _hiddenColumns = _hiddenColumns.filter(key => {
          return _columns.find(el => el.accessor === key) ? true : false
        })
      }
    }

    return Object.assign({
      defaultColumn, hasExpanded,
      initialState: {
        hiddenColumns: _hiddenColumns
      },
      RowWrap: rowWrap
    }, props, {columns}, parsed, {hiddenColumns: _hiddenColumns}, {
      data: parsed.data || []
    });
  }, [props]);

  const {
    hasExpanded, rowSelection, editable,
    canAddRow ,canEditRow, canDeleteRow,
    hiddenColumns, sorting
  } = options;

  const plugins = useMemo(() => {
    const pls = [useStyle, useContext];
    if (sorting) pls.push(useSortBy);
    if (hasExpanded) pls.push(useExpanded, useExpandedColumn);
    if (rowSelection) {
      pls.push(useRowSelect, useRowSelectExtend);
    }
    if (editable) {
      pls.push(useEditable);
    } else if (canAddRow || canEditRow || canDeleteRow) {
      pls.push(useRowEdit);
    } else if (hiddenColumns && hiddenColumns.length) {
      pls.push(useRowDetail);
    }
    pls.push(useFooter);
    pls.push(useExport);
    return pls;
  }, [
    hasExpanded, rowSelection, hiddenColumns,
    editable,  canAddRow ,canEditRow, canDeleteRow,
    sorting
  ]);

  return {
    options, plugins
  };
}