import React from 'react';
import { SingleDatePicker } from 'react-dates';
import 'antd/lib/date-picker/style/index.css';
import cls from 'classnames';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

export function validateValue(value) {
  let v = null;
  if (value) try {
    if (typeof value === 'string') {
      v = moment(value);
    } else {
      v = value;
    }
    if (v.isValid && value.isValid()) {
      return v;
    }
  } catch (err) {}
  return v;
}

function isOutsideRange() {
  return false;
}

function DatePickerInput(props) {
  const [focused, setFocus] = React.useState(false);
  const onFocusChange = React.useCallback(({focused}) => setFocus(focused), []);

  return (
    <div className={cls("date-picker relative rounded-md shadow-sm", props.className)}>
      <SingleDatePicker
        id={`${props.name}-date-picker`}
        date={validateValue(props.value)}
        onDateChange={props.onChange}
        focused={focused}
        onFocusChange={onFocusChange}
        noBorder={true}
        displayFormat={props.format}
        hideKeyboardShortcutsPanel={true}
        isOutsideRange={isOutsideRange}
        numberOfMonths={1}
        // showClearDate 
        // reopenPickerOnClearDate
        {...(props.props||{})}
      />
    </div>
  )
}

export default DatePickerInput;