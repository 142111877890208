import React from 'react';
import Button from 'components/Button';
import { TableContext } from '../../Beta';
import RowForm from './RowForm';

function AddRowButton() {
  const {
    onChange,
    addRowText = "Add row"
  } = React.useContext(TableContext);
  const [open, setOpen] = React.useState(false);
  const onAddRow = React.useCallback(() => setOpen(true), []);
  const onClose = React.useCallback(() => setOpen(false), []);
  
  const onSubmit = React.useCallback(data => {
    onChange({
      type: 'add',
      id: null,
      data
    });
  }, [onChange]);

  return (
    <div className="px-6 py-4 flex justify-center">
      <Button
        onClick={onAddRow}
        secondary
        icon="o-plus"
      >{addRowText}</Button>
      {
        open && <RowForm 
          onClose={onClose}
          onSubmit={onSubmit}
        />
      }
    </div>
  )
}

const AddRowButtonMemo = React.memo(AddRowButton);

function AddRowButtonWrap() {
  const { canAddRow, visibleColumns } = React.useContext(TableContext)
  if (canAddRow) {
    return <tr>
      <td colSpan={visibleColumns.length}>
        <AddRowButtonMemo />
      </td>
    </tr>
  }
  return null;
}

export default React.memo(AddRowButtonWrap);