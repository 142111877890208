import React from 'react';
import { components } from 'react-select';

const errColor = "#f8b4b4";

export const controlStyles = (base, state) => {
    const hasError = state.selectProps?.error ? true : false

    return {
        ...base,
        backgroundColor: '#ffffff',
        borderColor: hasError ? errColor : '#d2d6dc',
        padding: 0,
        backgroundImage: 'none',
        // boxShadow: "inherit",
        borderRadius: "0.375rem",
        borderWidth: "1px",
        fontSize: "1rem",
        "WebkitAppearance": "none",
        "MozAppearance": "none",
        "appearance": "none",
        "WebkitPrintColorAdjust": "exact",
        "colorAdjust": "exact",
        "fontWize": "1rem",
        "lineHeight": "1.5",
        '&:hover': {
            borderColor: hasError ? errColor :'inherit !important',
        },
        ...(state.isFocused ? {
            outline: "none",
            boxShadow: hasError ? "0 0 0 3px rgba(248, 180, 180, 0.45)" : "0 0 0 3px rgba(164, 202, 254, 0.45)",
            borderColor: hasError ? errColor : "#a4cafe"
        }: {})
    };
};

function Control(props) {
    return (
        <components.Control className="sm:text-sm sm:leading-5 shadow-sm" {...props} >
            { props.children }
        </components.Control>
    )
}

export default Control;