import React from 'react';
import Icon from 'components/Icon';
import { render } from './DefaultCell';

export function Expanded({ 
  canExpand,
  isExpanded,
  depth = 0,
  children,
  ...props
}) {
  return <div className="flex items-center">
    <span className={`inline-flex ml-${depth * 5}`}>
      {canExpand ? (
        <span
          className="mr-1"
          {...props}
        >
          {
            isExpanded
              ? <Icon className="h-4 w-4" name="o-chevron-down" />
              : <Icon className="h-4 w-4" name="o-chevron-right" />
          }
        </span>
      ) : (
          <span className="block w-4 h-4 mr-1" />
        )}
    </span>
    {children}
  </div>
}

function ExpandedCell({
  hasExpanded, columns, 
  column, row, cell
}) {
  if (hasExpanded) {
    if (columns[0].id === column.id) {
      return <Expanded 
        depth={row.depth}
        canExpand={row.canExpand}
        isExpanded={row.isExpanded}
        {...row.getToggleRowExpandedProps()}
      >
        {cell.render('Cell')}
      </Expanded>
    } else if (row.canExpand && !row.isExpanded) {
      const total = row.originalSubRows.find(el => el.isTotal);
      if (total) {
        return render(total[column.id], column.renderer);
      }
    }
  }
  return cell.render('Cell');
}

export default ExpandedCell;