import { combineReducers } from 'redux';
import produce from "immer";
import {
  apis
} from '../constants';

const loading = produce((draft, {type, payload}) => {
  switch (type) {
    case apis.fetch:
      draft[payload.name] = true;
      break;
    case apis.fetchSuccess:
    case apis.fetchError:
      draft[payload.name] = false;
      break;
    default:
      break;
  }
}, {});

export default combineReducers({
  loading
});

export function loadingSelectorByKey(key) {
  return st => typeof st.layout.loading[key] !== "undefined"
  ? st.layout.loading[key]
  : false; 
}