import { useFormContext } from "react-hook-form";
import { useRef, useEffect } from 'react';
import _get from 'lodash/get'

export function useFormItemError(name) {
  const ref = useRef();
  const { errors, formState } = useFormContext();
  const { submitCount } = formState;
  const error = _get(errors, name)?.message; 
  const isFirst = Object.keys(errors || {})[0] === name;

  useEffect(() => {
    if (ref.current && isFirst && error) {
      ref.current.scrollIntoView({
        behavior: 'smooth'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitCount]);

  return {
    ref, error
  };
}