import React, { memo, forwardRef } from 'react';
import classnames from 'classnames';

function FormItem({
    description,
    className,
    label,
    children,
    error,
    hidden,
    name,
    type
}, ref){
    const isHidden = type === 'hidden'|| hidden;
    return (
        <div 
            ref={ref}
            className={classnames(className, {
                hidden: isHidden
            })}
        >
            {label && <label 
                htmlFor={name} 
                className="block text-sm font-medium leading-5 text-gray-700 mb-1"
            >
                {label}
            </label>}
            { children }
            { 
                (description || error) 
                && <p 
                    id={error && name ? `${name}-error` : null}
                    className={classnames("mt-2 text-sm", {
                        "text-gray-500": !error,
                        "text-red-600": error
                    })}
                >
                    { error || description }
                </p>
            }
        </div>
    )
}

export default memo(forwardRef(FormItem));