import React from 'react'
import * as CardType from './CardType'
import * as StripedType from './StripedType'
import * as ColumnType from './ColumnType'
import * as InlineType from './InlineType'

function DescriptionList({
  values, title, description,
  className, uiType, py
}) {

  values = React.useMemo(() => {
    if (!values) return [];
    if (Array.isArray(values)) {
      return values;
    }
    if (typeof values === 'object') {
      return Object.keys(values).map(name => ({
        name,
        value: values[name]
      }))
    }
    return [];
  }, [values]);

  const List = React.useMemo(() => {
    switch (uiType) {
      case 'striped':
        return StripedType;
      case 'inline':
        return InlineType;
      case 'column':
        return ColumnType;
      default:
        return CardType;
    }
  }, [uiType]);

  return (
    <div className={className}>
      {(title || description) && <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
        {title && <h3 className="text-lg leading-6 font-medium text-gray-900">
          {title}
        </h3>}
        {description && <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
          {description}
        </p>}
      </div>}
      <List.Wrap py={py}>
        {
          values.map((el, _key) => (
            <List.Item 
              key={_key} 
              isOdd={_key % 2}
              isFirst={_key === 0} 
              py={py}
              {...el}
            />
          ))
        }
      </List.Wrap>
    </div>
  )
}

export default React.memo(DescriptionList);