import React from 'react';
import Icon from 'components/Icon';

function EmptyCell() {
  return (
    <span className="text-gray-300 text-lg font-light">
      <Icon className="w-5 h5" name="s-dots-horizontal"/>
    </span>
  );
}

export default EmptyCell;