import React from 'react';
import Item from '../FormElements/FormItem';
import { useFormItemError } from 'hooks/useFormItemError';

function FormItem({
  label, description,
  name, validationOptions, type,
  children, className
}) {
  const { ref, error } = useFormItemError(name);
  const { required } = validationOptions || {};

  return (
    <Item
      ref={ref}
      label={label}
      error={error}
      description={description}
      required={required}
      type={type}
      name={name}
      className={className}
    >
      { children }
    </Item>
  );
}

export default React.memo(FormItem);