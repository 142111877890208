import { useMemo } from 'react';
import { useQuery } from 'react-query';
import API from '@aws-amplify/api';
import { apiName, siteContentApi } from 'store/constants';
import siteContent from 'utils/siteContentApi';
import _get from 'lodash/get';

function queryFn({queryKey}) {
  const [url, api, method, parameters, type, path] = queryKey;
  let param = {};
  if (method === 'post') {
    param = { body: parameters };
  } else {
    param = { params: parameters };
  }

  let q = null;

  if (api === siteContentApi) {
    q = siteContent(method, url, param);
  } else {
    q = API[method](api, `/actions/${url}/exec${type ? `?type=${type}` : ''}`, param);
  }
  return q.then(res => {
    if (path) return _get(res, path);
    return res;
  })
}

export default function useAction(props, options) {
  const { 
    action, type, url, path,
    api, method, parameters
  } = props;

  const queryKey = useMemo(() => {
    const aaa = [
      url || action,
      api || apiName,
      method || 'post',
      parameters || {},
      type,
      path
    ]
    return aaa;
  },[
    action, type, url, api, 
    method, parameters, path
  ]);

  options = useMemo(() => ({
    // refetchOnWindowFocus: false,
    // refetchOnMount: false,
    staleTime: 5*60*1000, //5min
    ...(options || {})
  }), [options]);
  

  return useQuery(queryKey, queryFn, options);
}