import React from 'react';
import Logo from '../CompanyLogo';
import { qIdentity } from 'actions/auth'
import Auth from '@aws-amplify/auth';
import PulseLoader from 'components/PulseLoader';

function AuthLayout({
  title, description,
  children
}) {

  if (qIdentity) {
    Auth.federatedSignIn({provider: qIdentity})
    return <PulseLoader fullScreen />
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        <div>
          <Logo size="16"/>
          { 
            title && <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
              { title }
            </h2>
          }
          {
            description && <p className="mt-2 text-center text-sm leading-5 text-gray-600">
              { description }
            </p>
          }
        </div>
        { children }
      </div>
    </div>
  )
}

export default React.memo(AuthLayout);