import React from 'react';
import Sorted from './Sorted';

function Header({
  column
}) {
  const { canSort, isSorted, isSortedDesc } = column;

  const sorted = React.useMemo(() => {
    if (!canSort) return null;
    return (
      <Sorted
        isSorted={isSorted}
        isDesc={isSortedDesc}
      />
    );
  }, [canSort, isSorted, isSortedDesc]);

  return (
    <div className="items-center inline-flex">
      { column.render('Header')}
      { sorted}
    </div>
  )
}

export default Header;