import Auth from '@aws-amplify/auth';
import { apiName } from 'store/constants';
import conf from './index';

let path = '';
if (window.location.search.includes('code=') && window.location.pathname !== '/') {
  path = window.location.pathname;
}

const config = {
  Auth: {
    oauth: {
      domain: 'auth.hala.ai',
      scope: ['openid', 'email', 'phone', 'profile', 'aws.cognito.signin.user.admin'],
      redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN + path,
      redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
      responseType: 'code'
    },
    identityPoolId: 'us-east-1:1a2b132e-14c0-44e4-a317-d256b2f5b9eb',
    region: 'us-east-1',
    userPoolId: 'us-east-1_cKkQOpkAQ',
    userPoolWebClientId: '6hv4pvroeqck1u4okke6f30ksq',
    mandatorySignIn: true
  },
  API: {
    endpoints: [{
      name: apiName,
      endpoint: process.env.REACT_APP_API_ENDPOINT,
      "custom_header": async () => {
        return { 
          Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
          instanceKey: conf.instanceKey
        };
      }
    }]
  },
  Storage: {
    AWSS3: {
      bucket: 'hala-us-data',
      region: 'us-west-1'
    }
  }
};

export default config;