import React from 'react';
import cls from 'classnames';
import Toggle from './Toggle'

function Checkbox({
  className, label, description,
  error, type, ...props
}, ref) {
  const isToggle = type === 'toggle';
  return (
    <div ref={ref} className={cls("relative flex items-start", className)}>
      <div className="absolute flex items-center h-5">
        {
          type === 'toggle'
          ? <Toggle {...props}/>
          : <input 
            id={props.name}
            type="checkbox"
            className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" 
            {...props}
          />
        }
      </div>
      <div className={cls("text-sm leading-5", {
        "pl-7": !isToggle,
        "pl-14": isToggle
      })}>
        { <label htmlFor={props.name} className="font-medium text-gray-700">
            { label }
          </label>
        }
        { description && <p className="text-gray-500">
            { description }
          </p>
        }
        { error && <p className="text-red-600">
            { error }
          </p>
        }
      </div>
    </div>
  )
}

export default React.forwardRef(Checkbox);