import React, { memo } from 'react';
import { Controller, useFormContext } from "react-hook-form";
import LTInput from '../FormElements/LongText';
import useFormFieldOptions from 'hooks/useFormFieldOptions';

const LongTextInput = memo(({
    name, value, validationOptions, 
    fieldOptions, ...props
}) => {
    const { control, errors } = useFormContext();
    value = value || '';
    const error = errors && errors[name] && errors[name].message;
    useFormFieldOptions(name, fieldOptions);

    return (
        <Controller 
            as={<LTInput {...props} error={error}/>}
            name={name}
            defaultValue={value}
            control={control}
            rules={validationOptions}
        />
    );
});

export default memo(LongTextInput);