import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import FormItems from '../FormItems';
import Actions from './Actions';
import cls from 'classnames';

function getDefaultValues(items) {
  return items.reduce((obj, { name, value, items }) => {
    if (items && items.length) {
      obj = {
        ...obj,
        ...(getDefaultValues(items))
      }
    } else if (value && name) {
      obj[name] = value;
    }
    return obj;
  }, {});
}

function parseData(d) {
  Object.keys(d).forEach(key => {
    if (Array.isArray(d[key])) {
      d[key] = d[key].map(el => {
        if (el.value && el.label) {
          return el.value;
        }
        return el;
      })
    } else if (typeof d[key] === 'object' && d[key] && d[key].value) {
      d[key] = d[key].value
    }
  });
  return d;
}

function FormSchema({
  items, values = {},
  onSubmit,
  formOptions,
  actions, fixedActions, onCancel,
  parseOnSubmit, className, children,
  loading, header,
  ...props
}, ref) {
  const options = React.useMemo(() => {
    let _ops = formOptions || {};
    const { defaultValues = {} } = _ops;
    const def = getDefaultValues(items);
    _ops.defaultValues = {
      ...def,
      ...defaultValues,
      ...values
    };
    return _ops;
  }, [formOptions, items, values]);

  const methods = useForm(options);
  const { 
    handleSubmit
  } = methods;
  const _onSubmit = React.useMemo(() => handleSubmit(data => {
    if (parseOnSubmit) {
      data = parseData(data)
    }
    onSubmit && onSubmit(data);
  }), [handleSubmit, onSubmit, parseOnSubmit]);

  React.useImperativeHandle(ref, () => ({
    ...methods,
    submit: _onSubmit
  }), [methods, _onSubmit]);

  return (
    <div 
      className={cls(className, {
        "w-full h-full": fixedActions
      })}
      {...props}
    >
      <FormProvider {...methods}>
        <form 
          className={cls({
            "h-full divide-y divide-gray-200 flex flex-col": fixedActions
          })}
          onSubmit={_onSubmit}
        >
          {header}
          <div className={cls("space-y-6 pb-6", {
            "min-h-0 flex-1 flex flex-col overflow-y-scroll overflow-hidden": fixedActions
          })}>
            <FormItems 
              items={items} 
              withHeader={header?true:false}
            />
          </div>
          {children}
          {actions && <Actions 
            loading={loading}
            onCancel={onCancel}
            fixed={fixedActions}
          />}
        </form>
      </FormProvider>
    </div>
  );
}

export default React.memo(React.forwardRef(FormSchema));