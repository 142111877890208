import ExpandedHeader from '../ExpandedHeader';

export default function hook(hooks) {
  hooks.visibleColumns.push(columns => columns.map((column, k) => {
    if (k === 0) {
      column.value = column.Header;
      column.Header = ExpandedHeader;
    }
    return column;
  }))
}