import React from 'react';
import { authLoading } from 'actions/auth';
import { connect } from 'react-redux';
import Button from 'components/Button';
import { qEmail } from 'actions/auth'
import FormSchema from 'components/Form/FormSchema'
import isEmail from 'validator/es/lib/isEmail';
import { AutoSignContext } from '../AutoSignIn'
import Auth from '@aws-amplify/auth';

const items = [{
  type: "email",
  name: "email",
  label: "Email address",
  value: qEmail || undefined,
  disabled: qEmail ? true : undefined,
  validationOptions: {
    validate: value => {
      if (!value || !value.length) {
        return "Email is required";
      }
      if (!isEmail(value)) {
        return "Invalid email address";
      }
      return true;
    }
  }
}, {
  type: "password",
  name: "password",
  label: "Password",
  validationOptions: {
    required: "Password is required",
    pattern: {
      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,40}$/,
      message: 'Password must contain minimum eight characters, at least one uppercase, lowercase, number and special character!'
    }                             
  }
}, {
  type: "text",
  name: "firstName",
  label: "First name",
  validationOptions: {
    required: "First name is required"
  }
}, {
  type: "text",
  name: "lastName",
  label: "Last name",
  validationOptions: {
    required: "Last name is required"
  }
}];

function SignUpForm({
  onSubmit, onError,
  loading, authLoading
}) {
  const setAutoSign = React.useContext(AutoSignContext);
  const _onSubmit = React.useCallback(data => {
    if (loading) return false;
    authLoading(true);

    Auth.signUp({
      username: data.email,
      password: data.password,
      attributes: {
        name: data.firstName,
        family_name: data.lastName
      }
    }).then(res => {
      setAutoSign({
        email: data.email,
        password: data.password
      });
      onSubmit({
        email: data.email,
        username: res.user.username
      });
    })
    .catch(onError)
    .finally(() => {
      authLoading(false);
    })
  }, [loading, authLoading, onSubmit, setAutoSign, onError]);

  return (
    <FormSchema
      items={items}
      onSubmit={_onSubmit}
    >
      <div className="mt-6">
        <Button 
          type="submit" 
          className="w-full"
          iconLeft
          loading={loading}
        >
          Sign up
        </Button>
      </div>
    </FormSchema>
  )
}

const props = state => ({
  loading: state.auth.loading
});

const actions = { authLoading };

export default connect(
  props, actions
)(SignUpForm);