import produce from "immer";
import { createSelector } from 'reselect';
import {
  SIGNED_IN,
  SIGNED_OUT,
  SIGN_IN,
  SET_USER_INFO,
  VERIFY_CONTACT,
  REQUIRE_NEW_PASSWORD,
  AUTH_LOADING,
  AUTH_ERROR,SERVER_MESSAGE
} from '../constants';
import { firstSymbol } from "utils";

const initialState = {
  authState: '',
  user: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    icon: '',
    roles: [],
    sub: ''
  },
  workspaceName: '',
  loading: false
};

export default produce((draft, { type, authData }) => {
  switch (type) {
    case VERIFY_CONTACT:
    case REQUIRE_NEW_PASSWORD:
    case SIGN_IN:
      draft.authState = type;
      draft.loading = false;
      break;
    case AUTH_LOADING:
      draft.loading = authData;
      break;
    case AUTH_ERROR:
      draft.loading = false;
      break;
    case SIGNED_IN:
      draft.authState = type;
      draft.user.sub = authData.username;
      draft.loading = false;
      break;
    case SIGNED_OUT:
      return initialState;
    case SERVER_MESSAGE:
      if (draft.onboarding) {
        draft.onboarding = false;
      }
      break;
    case SET_USER_INFO:
      const {
        onboarding, workspaceName, ...rest
      } = authData;

      draft.user = {
        ...draft.user,
        ...rest,
        loading: false
      }
      if (authData.firstName || authData.lastName) {
        draft.user.icon = `${firstSymbol(draft.user.firstName)}${firstSymbol(draft.user.lastName)}`;
      }
      if (workspaceName) {
        draft.workspaceName = authData.workspaceName;
      }
      if (onboarding) {
        draft.onboarding = true;
      }
      break;
    default:
      break;
  }
}, initialState);

export const authSelector = state => state.auth;
export const userSelector = createSelector(
  authSelector,
  auth => auth.user
);
export const userEmailSelector = createSelector(
  userSelector,
  user => user.email
);
export const userFirstNameSelector = createSelector(
  userSelector,
  user => user.firstName
);
export const workspaceNameSelector = createSelector(
  authSelector,
  auth => auth.workspaceName
);
export const isOnboardingSelector = createSelector(
  authSelector,
  auth => auth.onboarding || false
);
export const userRolesSelector = createSelector(
  userSelector,
  user => user.roles
);
export const isCossackSelector = createSelector(
  userRolesSelector,
  roles => roles.includes('Cossack')
);

export const isAdminSelector = createSelector(
  userRolesSelector,
  roles => roles.includes('Admin')
);

export const isOwnerSelector = createSelector(
  userRolesSelector,
  roles => roles.includes('Owner')
);

export const isDeveloperSelector = createSelector(
  userRolesSelector,
  roles => roles.includes('Developer')
);

export const userIdSelector = createSelector(
  userSelector,
  user => user.id || null
);