import React, { Suspense} from 'react';
import {
  useTable
} from 'react-table';
import Row from './Row';
import HeaderGroup from './HeaderGroup';
import useTableOptions from './hooks/useTableOptions';
import AddRowButton from './hooks/useRowEdit/AddRowButton';
import cls from 'classnames';
import NoData from './NoData'
import Footer from './Footer'
import Loader from './Loader'

const ExportButton = React.lazy(() => import('./hooks/useExport/ExportButton'));

export const TableContext = React.createContext();

function Table(props) {
  const { plugins, options } = useTableOptions(props);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    isLoading,
    rows,
    prepareRow,
    withFooter,
    context,
    RowWrap,
    canExport, fileName,
    tbodyRef
  } = useTable(options, ...plugins);
  const {canAddRow, visibleColumns} = context;
  return (
    <TableContext.Provider value={context}>
      <>
      {
        canExport ? (
          <div className="w-full relative">
            <Suspense fallback={null}>
              <ExportButton
                rows={rows}
                fileName={fileName}
                className="absolute right-0 top-0 transform -translate-y-9"
              />
            </Suspense>
          </div>
        ) : null
      }
      <div className={cls("align-middle min-w-full border-t border-gray-200", {
        "overflow-x-auto overflow-hidden": context.tableOverflow
      })}>
        <table {...getTableProps({
          className: "min-w-full"
        })}>
          <thead>
            {headerGroups.map((header, key) => (
              <HeaderGroup key={key} header={header} />
            ))}
          </thead>
          <tbody ref={tbodyRef} {...getTableBodyProps()}>
            { isLoading && <Loader columnsLength={visibleColumns.length}/> }
            {!isLoading && !canAddRow && !rows.length ? <NoData 
              columnsLength={visibleColumns.length}
            /> : null}
            {rows.map((row, key) => {
              prepareRow(row);
              return (
                <RowWrap key={key} row={row}>
                  <Row row={row} />
                </RowWrap>
              )
            })}
            <AddRowButton/>
          </tbody>
          {withFooter ? <Footer footerGroups={footerGroups} /> : null}
        </table>
      </div>
      </>
    </TableContext.Provider>
  );
}

export default React.memo(Table);