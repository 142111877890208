import React from 'react';
import { Expanded } from './ExpandedCell';

function ExpandedHeader({
  getToggleAllRowsExpandedProps, isAllRowsExpanded,
  column
}) {

  return <Expanded
    canExpand={true}
    isExpanded={isAllRowsExpanded}
    {...getToggleAllRowsExpandedProps()}
  >
    {column.value}
  </Expanded>
}

export default ExpandedHeader;