import React from 'react';
import Slideover from 'components/Slideover';
import FormSchema from 'components/Form/FormSchema';
import FormButtons from './FormButtons';
import { TableContext } from '../../Beta';
import cls from 'classnames';

function RowForm({
  onClose, onSubmit, values = {}
}) {
  let {
    itemForm, slideover = {}
  } = React.useContext(TableContext)
  const ref = React.useRef();
  const triggerSubmit = React.useCallback(close => {
    ref.current && ref.current.handleSubmit(data => {
      onSubmit(data);
      close();
    })();
  }, [onSubmit]);

  itemForm = React.useMemo(() => {
    return itemForm.map(el => {
      if (el.type === 'table') {
        return el
      }
      return {
        ...el,
        className: cls('px-4 sm:px-6', el.className)
      }
    });
  }, [itemForm])

  return <Slideover
    onClose={onClose}
    footer={<FormButtons
      onSubmit={triggerSubmit}
    />}
    {...slideover}
  >
    <div className="py-4 sm:py-6">
      <FormSchema
        ref={ref}
        items={itemForm}
        values={values}
      />
    </div>
  </Slideover>
}

export default React.memo(RowForm);