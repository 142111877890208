import { useEffect, useMemo } from 'react';
import _get from 'lodash/get';
import { useFormContext } from "react-hook-form";
import { compile } from 'utils/hb';

function useAutoFill(name, options = {}) {
  const { watch, setValue, getValues } = useFormContext();
  const { onChange } = options;
  const val = useMemo(() => {
    if (onChange) {
      let val = watch(onChange);
      if (typeof val === 'object') {
        return JSON.stringify(val);
      }
      return val;
    }
    return null;
  }, [onChange, watch])
  const { tpl, key } = options;
  const _tpl = useMemo(() => {
    if (tpl) {
      return compile(tpl);
    }
    return null;
  }, [tpl])

  useEffect(() => {
    if (val) {
      const vals = getValues();
      let _val = undefined;
      if (_tpl) {
        _val = _tpl(vals);
      } else if (key) {
        _val = _get(vals, key);
      }
      if (_val !== undefined && vals[name] !== _val) {
        setValue(name, _val, { shouldValidate: true }); 
      } 
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val]);
}

export default function useFormFieldOptions(name, options) {
  const { autoFill } = options || {};
  useAutoFill(name, autoFill);
}