import React from 'react';
import cls from 'classnames';
import Fieldset from './Fieldset';

export function RadioOption({
  k, label, value,
  ...props
}) {
  let description = null;
  if (typeof label !== 'string') {
    description = label.description;
    label = label.label;
  }
  const id = label.toLowerCase().replace(/ /g, '_');

  return (
    <div className={cls("flex items-center", {
      'mt-4': k > 0
    })}>
      <input
        id={id}
        className={cls("form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out")}
        value={label}
        checked={value === label}
        {...props}
      />
      <div className="ml-3">
        <label htmlFor={id}>
          <span className="block text-sm leading-5 font-medium text-gray-700">
            {label}
          </span>
        </label>
        {description && <p className="text-gray-500 text-sm leading-5">
          {description}
        </p>
        }
      </div>
    </div>
  );
}

function RadioInput({
  className,
  options,
  error,
  label,
  description,
  ...props
}, ref) {
  return (
    <Fieldset 
      ref={ref}
      className={className} 
      label={label}
      description={description}
      error={error}
    >
      {
        options && options.map((o, k) => (
          <RadioOption
            key={k}
            k={k}
            label={o}
            {...props}
          />
        ))
      }
    </Fieldset>
  )
}

export default React.forwardRef(RadioInput);