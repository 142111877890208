import React from 'react';
import Fieldset from 'components/FormElements/Fieldset';
import { useFormItemError } from 'hooks/useFormItemError';

function FieldsetItem({
  name, ...props
}) {
  const { ref, error } = useFormItemError(name);

  return (
    <Fieldset
      ref={ref}
      error={error}
      {...props}
    />
  );
}

export default React.memo(FieldsetItem);