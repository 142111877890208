import React from 'react'
import ContentLoader from 'react-content-loader';

function CellLoader(props) {
  return (
    <ContentLoader
      speed={2}
      width={100}
      height={20}
      viewBox="0 0 100 20"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="0" rx="0" ry="0" width="100" height="20" />
    </ContentLoader>
  )
}

export default React.memo(CellLoader);