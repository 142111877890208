// import React from 'react';
// import intuitSignInImage from './IntuitSignIn-md-white.jpg';
// import { Auth } from 'aws-amplify';

function IdentityProviders() {
  return null;
  // return (
  //   <div className="mt-6">
  //     <div className="relative">
  //       <div className="absolute inset-0 flex items-center">
  //         {/* <div className="w-full border-t border-gray-300"></div> */}
  //       </div>
  //       <div className="relative flex justify-center text-sm leading-5">
  //         <span className="px-2 text-gray-500">
  //           Or continue with
  //         </span>
  //       </div>
  //     </div>
  //     <div className="mt-6">
  //       <button 
  //         onClick={() => Auth.federatedSignIn({provider: 'Intuit'})} 
  //         type="button" 
  //         className="w-full inline-flex focus:outline-none" 
  //         aria-label="Sign in with Intuit"
  //       >
  //         <img className="h-11 mx-auto" src={intuitSignInImage} alt="Sign in with Intuit"/>
  //       </button>
  //     </div>
  //   </div>
  // )
}

export default IdentityProviders;