import React from 'react'

function NoData({columnsLength}) {
  return <tr>
    <td colSpan={columnsLength}>
      <div className="w-full text-gray-400 font-semibold text-center py-28">
        No Data
      </div>
    </td>
  </tr>
}

export default React.memo(NoData);