import React from 'react';
import Checkbox from 'components/FormElements/Checkbox';

export default React.forwardRef(({ 
    indeterminate, ...rest 
}, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef
  
    React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
  
    return (
        <Checkbox 
            ref={resolvedRef}
            {...rest}
        />
    )
})