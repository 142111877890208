import React from 'react';
import Icon from 'components/Icon';
import Transition from 'components/Transition/Spring';
import {
  easeCubicOut
} from 'd3-ease';
import useOnClickOutside from 'hooks/useOnClickOutside';
import cls from 'classnames';
import { Link } from 'react-router-dom'

export const DropdownContext = React.createContext();

const animation = {
  config: {
    duration: 200,
    easing: easeCubicOut
  },
  from: { transform: 'scale(0.95,0.95)', opacity: 0 },
  enter: { transform: 'scale(1,1)', opacity: 1 },
  leave: { transform: 'scale(0.95,0.95)', opacity: 0 }
};

function A(props) {
  return <a {...props}>{props.children}</a>;
}

function Item({
  name, icon, onClick, close, to
}) {
  let Comp = A;
  let pr = {
    onClick:e => {
      close();
      e.preventDefault();
      onClick && onClick();
    },
    href: "/"
  }
  if (to) {
    if (to.includes('https://')) {
      Comp = A;
      pr = {
        href: to,
        target: '_blank',
        rel: "noopener noreferrer"
      }
    } else {
      Comp = Link;
      pr = {
        to,
        onClick: e => {
          close();
          onClick && onClick();
        }
      }
    }
  }
  return (
    <Comp
      {...pr}
      className="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" 
      role="menuitem"
    >
      {icon && <Icon 
        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" 
        name={icon} 
      />}
      {name}
    </Comp>
  );
}

const DropdownItem = React.memo(Item);

function Dropdown({
  children, items, Toggle, footer,
  widthClass = "w-48", position = 'left',
  className, onChange
}) {
  const [show, setShow] = React.useState(false);
  const toggle = React.useCallback(() => setShow(s => !s), []);
  const close = React.useCallback(() => setShow(false), []);
  const ref = useOnClickOutside(close);

  React.useEffect(() => {
    onChange && onChange(show);
  }, [onChange, show]);

  Toggle = React.useMemo(() => {
    if (!Toggle) return null;
    return React.cloneElement(Toggle, {
      onClick: toggle
    });
  }, [toggle, Toggle]);

  items = React.useMemo(() => {
    const n = items.length - 1;
    if (n < 0) return null;
    return items.reduce((res, props, key) => {
      if (props.border) {
        res.items.push(
          <div key={`g-${key - 1}`} className="py-1">{res.group}</div>,
          <div key={`b-${key}`} className="border-t border-gray-100"></div>
        );
        res.group = [];
      }
      res.group.push(<DropdownItem close={close} key={key} {...props} />);
      if (n === key) {
        res.items.push(
          <div key={`g-${key}`} className="py-1">{res.group}</div>
        );
        return res.items;
      }
      return res;
    }, { items: [], group: [] });
  }, [items, close]);

  return (
    <div
      ref={ref}
      className={cls("relative flex justify-end items-center", className)}
    >
      {Toggle}
      <Transition
        show={show}
        className={cls("mx-3 z-20 origin-top-right absolute mt-1 rounded-md shadow-lg", {
          "right-7 top-0": position === 'left',
          "right-0 top-9": position === 'bottom',
          [widthClass]: widthClass
        })}
        options={animation}
      >
        <DropdownContext.Provider value={close}>
          <div className="rounded-md bg-white shadow-xs">
            {children}  
            {
              children && items && <div className="border-t border-gray-100"></div>
            }
            {items}
            {footer}
          </div>
        </DropdownContext.Provider>
      </Transition>
    </div>
  )
}

export default React.memo(Dropdown);