
function useInstance(instance) {
  const {
    canExport = false
  } = instance;

  Object.assign(instance, {
    canExport
  });
}

export default function hook(hooks) {
  hooks.useInstance.push(useInstance);
}