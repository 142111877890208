import React from 'react'

function TableFooter({
  footerGroups
}) {
  return (
    <tfoot className="bg-white">
      {footerGroups.map(group => (
        <tr {...group.getFooterGroupProps()}>
          {group.headers.map(column => (
            <td {...column.getFooterProps()}>
              {column.render('FooterCell')}
            </td>
          ))}
        </tr>
      ))}
    </tfoot>
  )
}

export default React.memo(TableFooter);