import React from 'react';
import Select from "../FormElements/Select";
import { useFormContext, Controller } from "react-hook-form";
import { useFormItemError } from 'hooks/useFormItemError';

function FormSelect({
  name, value, validationOptions,
  onChange, ...props
}) {
  const { control } = useFormContext();
  const { error } = useFormItemError(name);
  value = value || '';
  const pOnChange = onChange;

  return (
    <Controller
      render={({onChange, ...rest }) => {
        const _onChange = value => {
          if (value?.target) value = value.target.value;
          onChange(value);
          pOnChange && pOnChange(value);
        };
        return (
          <Select
            onChange={_onChange}
            name={name}
            error={error}
            {...props}
            {...rest}
          />
        )
      }}
      name={name}
      defaultValue={value}
      control={control}
      rules={validationOptions}
    />
  )
}

export default React.memo(FormSelect);