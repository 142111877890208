import React from 'react';

export const multiValueLabelStyles = base => ({
});

function MultiValueLabel(props) {
    return (
        <>
            { props.children }
        </>
    );
};

export default MultiValueLabel;