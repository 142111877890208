import React from 'react';
import { authLoading } from 'actions/auth';
import { connect } from 'react-redux';
import Button from 'components/Button';
import { useForm } from "react-hook-form";
import { inputErrClass } from 'components/FormElements';
import cls from 'classnames';
import Icon from 'components/Icon';
import {qEmail} from 'actions/auth'
import { SIGN_UP } from 'store/constants'

function SignInForm({
  onSubmit, loading, authLoading,
  changeState
}) {
  const { handleSubmit, register, errors } = useForm({
    defaultValues: {
      username: qEmail || ''
    }
  });

  const onResetPass = React.useCallback(e => {
    e.preventDefault();
    changeState('forgotPassword');
  }, [changeState]);

  const _onSubmit = React.useCallback((d, e) => {
    if (loading) return false;
    authLoading(true);
    onSubmit(d, e);
  }, [loading, authLoading, onSubmit]);

  const emailError = errors && errors.username && errors.username.message;
  const passwordError = errors && errors.password && errors.password.message;
  
  return (
    <form className="mt-8" onSubmit={handleSubmit(_onSubmit)}>
      <div className="rounded-md shadow-sm">
        <div className="relative">
          <input 
            aria-label="Email address" 
            name="username" 
            type="email" 
            required 
            className={cls("appearance-none rounded-none relative block w-full px-3 py-2 border rounded-t-md focus:outline-none focus:z-10 sm:text-sm sm:leading-5",{
              "border-gray-300 placeholder-gray-500 text-gray-900 focus:shadow-outline-blue focus:border-blue-300": !emailError,
              [`pr-10 ${inputErrClass}`]: emailError
            })} 
            placeholder="Email address"
            // disabled={qEmail?true:false} 
            ref={register({
              required: "Email address is required",
              // pattern: {
              //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              //   message: "invalid email address"
              // }
            })}
            aria-invalid={emailError ? "true" : null}
            aria-describedby={emailError ? `email-error` : null}
          />
          {
            emailError && <div className="absolute z-10 inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <Icon className="h-5 w-5 text-red-500" name="s-exclamation-circle" />
            </div>
          }
        </div>
        <div className="-mt-px relative">
          <input 
            aria-label="Password"
            name="password" 
            type="password" 
            required 
            className={cls("appearance-none rounded-none relative block w-full px-3 py-2 border rounded-b-md focus:outline-none focus:z-10 sm:text-sm sm:leading-5", {
              "border-gray-300 placeholder-gray-500 text-gray-900 focus:shadow-outline-blue focus:border-blue-300": !passwordError,
              [`pr-10 ${inputErrClass}`]: passwordError
            })} 
            placeholder="Password" 
            ref={register({
              required: "Password is required",
              // pattern: {
              //   value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,40}$/,
              //   message: "Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character!"
              // }
            })}
            aria-invalid={passwordError ? "true" : null}
            aria-describedby={passwordError ? `password-error` : null}
          />
          {
            passwordError && <div className="absolute z-10 inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <Icon className="h-5 w-5 text-red-500" name="s-exclamation-circle" />
            </div>
          }
        </div>
      </div>
      { 
        (passwordError || emailError)
        && <p className="mt-2 text-sm text-red-600">
          { emailError || passwordError }
        </p>
      }
      <div className="mt-6 flex items-center justify-end">
        <div className="text-sm leading-5">
          <a href="/" 
            onClick={onResetPass}
            className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
          >
            Forgot your password?
          </a>
        </div>
      </div>
      <div className="mt-6 flex justify-between items-center">
        <Button 
          type="submit" 
          className="w-full"
          icon="s-lock-closed"
          iconLeft
          iconTransparent
          loading={loading}
        >
          Sign in
        </Button>
        <span className="text-gray-500 mx-6">or</span>
        <Button 
          secondary
          className="w-full"
          onClick={() => changeState(SIGN_UP)}
        >
          Sign up for free
        </Button>
      </div>
    </form>
  )
}

const props = state => ({
  loading: state.auth.loading
});

const actions = { authLoading };

export default connect(
  props, actions
)(SignInForm);