import React from 'react';
import { SignUp } from 'aws-amplify-react';
import AuthLayout from '../Layout';
import SignUpForm from './SignUpForm';
import { SIGN_IN, CONFIRM_SIGN_UP } from 'store/constants'
import TermsInfo from '../TermsInfo'

class MySignUp extends SignUp {

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data) {
    this.inputs = {
      username: data.email
    };
    this.changeState(CONFIRM_SIGN_UP, data.username);
  }

  showComponent() {
    const { hide } = this.props;
    if (hide && hide.includes(MySignUp)) {
      return null;
    }

    return (
      <AuthLayout
        title="Sign up to Hala platform"
        description={
          <>
            have an account?
            <span
              onClick={() => this.changeState(SIGN_IN)}
              className="pl-1 cursor-pointer font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
            >
              Sign in
            </span>
          </>
        }
      >
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <SignUpForm
              onSubmit={this.handleSubmit}
              onError={this.error}
            />
            <TermsInfo/>
          </div>
        </div>
      </AuthLayout>
    )
  }
}

export default MySignUp;