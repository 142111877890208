import React from 'react';
import { components } from 'react-select';

export const menuStyles = base => ({
    ...base,
    backgroundColor: 'inherit',
    borderRadius: 'inherit',
    boxShadow: 'inherit',
    margin: 0
});

function Menu(props) {
    return (
        <components.Menu {...props}>
            <div className="mt-1 w-full rounded-md bg-white shadow-lg z-10">
                <div className="rounded-md py-1 text-base leading-6 shadow-xs focus:outline-none sm:text-sm sm:leading-5">
                    {props.children}
                </div>
            </div>
        </components.Menu>
    );
};

export default Menu;