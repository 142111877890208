import React from 'react';
import { components } from 'react-select';
import { inpurStyles } from './defaultOptions';
import useOptions from './hooks/useOptions';
import Icon from 'components/Icon';

export const singleValueStyles = base => ({
  ...base,
  ...inpurStyles,
  color: "inherit"
})

function SingleValue(props) {

  const { renderValues } = props.selectProps;

  let val = props.getValue();
  if (val && val[0]) {
    val = val[0].value;
  }

  const {parentName} = useOptions(props);

  React.useEffect(() => {
    if (!props.children && props.data && props.options) {
      const _val = props.options.find(({value}) => value === props.data || value === props.data?.value);
      _val && props.setValue(_val)
    }
  }, [props]);

  const {icon, inlineIcon} = props.data;

  return (
    <components.SingleValue
      {...props}
    >
      <span className="w-full flex items-center space-x-2 truncate">
        {icon && <Icon className="h-5 w-5 text-gray-700" name={icon} />}
        {inlineIcon && <span>{inlineIcon}</span>}
        <span className="truncate">
          {props.children}
        </span>
        {(renderValues||parentName) && val && <span className="truncate text-gray-500">
          { renderValues ? val : parentName }
        </span>}
      </span>
    </components.SingleValue>
  );
};

export default SingleValue;