import React from 'react';
import cls from 'classnames';

function Toggle({
  checked, onChange, disabled
}) {
  const toggle = React.useCallback(() => onChange(!checked), [checked, onChange]);
  return (
    <span
      onClick={disabled ? null : toggle}
      role="checkbox"
      tabIndex="0"
      aria-checked={checked ? "true" : "false"}
      className={cls("relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline",{
        "bg-gray-200": !checked,
        "bg-indigo-600": checked,
        "opacity-50": disabled
      })}
    >
      <span
        aria-hidden="true"
        className={cls("translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200",{
          "translate-x-0": !checked,
          "translate-x-5": checked
        })}
      ></span>
    </span>
  )
}

export default React.memo(Toggle);