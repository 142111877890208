import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useOnClickOutside from 'hooks/useOnClickOutside';
import FormItemWrap from './FormItemWrap';
import FormItem from './FormItem';

const _field = {
  type: 'text',
  // validationOptions: {
  //   required: "this value is required"
  // }
};

const formOptions = {
  mode: 'all'
}

function EditableCell(props) {
  const {
    column: {
      field, id
    },
    row: {
      saveRow
    },
    cell: {
      goUp, goDown, goLeft, goRigth
    },
    value, closeEditable
  } = props;

  const f = React.useMemo(() => {
    return {
      ..._field,
      ...field
    }
  }, [field]);
  const methods = useForm(formOptions);
  const { isValid } = methods.formState;
  const { getValues } = methods;
  const saveAndClose = React.useCallback(() => {
    if (isValid) {
      saveRow(getValues());
    }
    closeEditable();
  }, [isValid, getValues, saveRow, closeEditable])
  const ref = useOnClickOutside(saveAndClose);
  const {type} = f;

  const onKeyDown = React.useCallback(e => {
    switch(e.keyCode){
      case 37:
        if (goLeft && ['text','select'].includes(type) && e.target.selectionStart === 0) {
          saveAndClose();
          goLeft();
        }
        break;
      case 38:
        if (goUp && ['text'].includes(type)) {
          saveAndClose();
          goUp();
        }
        break;
      case 39:
        if (goRigth && ['text','select'].includes(type) && e.target.value.length === e.target.selectionEnd) {
          saveAndClose();
          goRigth();
        }
        break;
      case 40:
        if (goDown && ['text'].includes(type)) {
          saveAndClose();
          goDown();
        }
        break;
      default:
        break;
    }
  }, [
    goUp, goDown, goLeft, goRigth, 
    saveAndClose, type
  ]);

  return (
    <FormProvider {...methods}>
      <FormItemWrap ref={ref} name={id}>
        <FormItem 
          name={id}
          defaultValue={value}
          field={f}
          saveAndClose={saveAndClose}
          onKeyDown={onKeyDown}
        />
      </FormItemWrap>
    </FormProvider>
  )
}

export default EditableCell;